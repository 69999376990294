import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../app/Helpers';
import { GroupMembershipEndpointClient } from '../../../../../../../repository/UserManagement/GroupmembershipServiceClientPb';
import { GetGroupMembershipResponse, GetGroupMembershipRequest, DeleteGroupMembershipResponse, DeleteGroupMembershipRequest } from '../../../../../../../repository/UserManagement/groupmembership_pb';
import { RolePrivilegeEndpointClient } from '../../../../../../../repository/UserManagement/RoleprivilegeServiceClientPb';
import { DeleteRolePrivilegeRequest, DeleteRolePrivilegeResponse, GetRolePrivilegeRequest, GetRolePrivilegeResponse } from '../../../../../../../repository/UserManagement/roleprivilege_pb';


export interface RolePrivilegeDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: RolePrivilegeDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new RolePrivilegeEndpointClient(API_URL, null, null);


export const getItem = createAsyncThunk<GetRolePrivilegeResponse.AsObject, APIRequest<GetRolePrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/rolePrivilegeDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getRolePrivilege(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const removeItem = createAsyncThunk<APIResponse<DeleteRolePrivilegeResponse.AsObject>, APIRequest<DeleteRolePrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/rolePrivilegeDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
export const managersRolePrivilegeDetailsPageSlice = createSlice({
    name: 'pages/management/roleDetails/rolePrivilegeDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getItem.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = {
                    id: payload?.success?.id,
                    privilegeName: payload?.success?.privilege?.name?.value ?? "",
                    privilegeId: payload?.success?.privilege?.id,
                    roleName: payload?.success?.role?.name?.value ?? "",
                    roleId: payload?.success?.role?.id,

                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.addedby?.firstname?.value ?? "") + " " + (payload?.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload?.success?.addedby?.id?.value,
                }
                state.item = r;

            
            }

        })
        builder.addCase(getItem.rejected, (state, action) => {
            state.isLoading = false;

            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItem.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(removeItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(removeItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetItem, updateItem } = managersRolePrivilegeDetailsPageSlice.actions

export default managersRolePrivilegeDetailsPageSlice.reducer