import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { LogEndpointClient } from '../../../../../../repository/UserManagement/Clients/LogServiceClientPb';
import { GetLogRequest, GetLogResponse } from '../../../../../../repository/UserManagement/Clients/log_pb';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';


export interface LogDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: LogDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new LogEndpointClient(API_URL, null, null);

export const get = createAsyncThunk<GetLogResponse.AsObject, APIRequest<GetLogRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/logDetails/clients/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getLog(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const clientLogsPageSlice = createSlice({
    name: 'pages/logDetails/clients',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {


        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    objectid: payload.success?.objectid?.value,
                    description: payload.success?.description?.value ?? "",
                    privilegeId: payload.success?.action?.id?.value,
                    privilegeName: payload.success?.action?.name?.value,
                    privilegeEndpoint: payload.success?.action?.endpoint?.value,
                    privilegeClass: payload.success?.action?.pb_class?.value,
                    endExecutionDate: formatDate(timestampToDate(payload.success?.endexecutiondate?.seconds, payload.success?.endexecutiondate?.nanos)),
                    startExecutionDate: formatDate(timestampToDate(payload.success?.startexecutiondate?.seconds, payload.success?.startexecutiondate?.nanos)),
                    type: payload.success?.type?.value,
                    clientIP: payload.success?.client?.ip?.value ?? "",
                    userAgent: payload.success?.client?.useragent?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.executer?.firstname?.value ?? "") + " " + (payload.success?.executer?.lastname?.value ?? ""),
                    addedById: payload.success?.executer?.id?.value,
                    exception: payload.success?.exception?.value,
                    inputs: payload.success?.inputs?.value,
                    outputs: payload.success?.outputs?.value,

                }
                /*if (payload?.success?.collectionaccountsList) {
                    let count = 0
                    payload?.success?.collectionaccountsList?.forEach(element => {
                        r.extraFields.push({ key: element.account?.id, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }*/

                state.item = r
            }

        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = clientLogsPageSlice.actions

export default clientLogsPageSlice.reducer