import { DefaultButton, DialogFooter, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, reset, update, getGroups, resetGroups, setSelectedGroup, setIsFilteredSet, setMessage } from "./DataRestriactionsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { AddGroupRequest, UpdateGroupRequest, AddGroupResponse, UpdateGroupResponse, GetUnRestrictedGroupsRequest, GetUnRestrictedGroupsResponse } from "../../../../repository/UserManagement/group_pb";
import { AddDataRestrictionRequest, UpdateDataRestrictionRequest, AddDataRestrictionResponse, UpdateDataRestrictionResponse } from "../../../../repository/UserManagement/datarestriction_pb";
import { TableState } from "../../../common/Table/TableSate";
import { getgroups } from "process";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { GroupsPage } from "../../../pages/Customers/Groups/GroupsPage";


let req: AddDataRestrictionRequest;
let updateReq: UpdateDataRestrictionRequest;
let groupReq: GetUnRestrictedGroupsRequest;

let promise: any;
let getGroupsPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const DataRestrictionsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const [currentAction, setCurrenctAction] = useState(0)

    const state: { isLoading: boolean, message: ApiMessage | undefined, groups: TableState } = useAppSelector((state) => {

        return { isLoading: state.customersDataRestrictionsForm.isLoading, message: state.customersDataRestrictionsForm.message, groups: state.customersDataRestrictionsForm.groups }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddDataRestrictionRequest();
        updateReq = new UpdateDataRestrictionRequest()
        groupReq = new GetUnRestrictedGroupsRequest();
        groupReq.setPrivilegedataid(props?.renderObject?.privilegeData?.id)
        groupReq.setNumofresults(state.groups.numberOfResults)
        groupReq.setOrder(state.groups.isDescending)
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                //req.setOrganization(wrapper)
               // updateReq.setOrganization(wrapper)
                groupReq.setOrganization(wrapper)
            }
        }

        if (props.renderObject?.group)
            dispatch(setSelectedGroup(([props.renderObject.role])))


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        if (!props.renderObject?.privilegeData?.dataType)
            dispatch(setMessage({ body: t("restrictionOnOutputDesc"), type: 3, title: "", data: undefined }))

        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const groupsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('groups'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.groups.hasMore && !state.groups.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.groups.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedGroup([state.groups.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.groups.items.length > 0 && !state.groups.isFetching) {
                groupReq.setNextto(state.groups.items.at(state.groups.items.length - 1).id)
                getGroupsPromise = dispatch(getGroups({ body: groupReq, headers: getHeaders() }))
            }
        },
        suggestions: state.groups.items.length > 0 ? state.groups.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.groups.items.length == 0 ? state.groups.isFetching : false,
        isSearching: state.groups.items.length > 0 ? state.groups.isFetching : false,

    };
    const onGroupFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetGroups())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        groupReq.setSearch(wrapper)
        groupReq.setNextto(0)
        dispatch(setIsFilteredSet(false))
        getGroupsPromise?.abort();
        getGroupsPromise = dispatch(getGroups({ body: groupReq, headers: getHeaders() }))

        return [];

    }
    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("groups")} onDismiss={() => { setCurrenctAction(0) }} >

                <GroupsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedGroup([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik


                initialValues={{
                    group: undefined,
                    enums: (props.renderObject?.enum && props.renderObject?.privilegeData?.dataType) ? props.renderObject?.enum as DynamicField[] : [] as DynamicField[],
                    blocked: (props.renderObject?.enum && props.renderObject?.privilegeData?.dataType) ? props.renderObject?.blocked as DynamicField[] : [] as DynamicField[],
                    range: (props.renderObject?.range && props.renderObject?.privilegeData?.dataType) ? props.renderObject?.range as DynamicField[] : [] as DynamicField[],
                    regex: (props.renderObject?.regex && props.renderObject?.privilegeData?.dataType) ? props.renderObject?.regex as string : ""

                }}

                validationSchema={props.renderObject?.privilegeData?.dataType ? Yup.object({

                    //username: inputs.username,
                    enums: inputs.enums,
                    blocked: inputs.blocked,
                    range: inputs.range,
                    regex: inputs.regex,

                }) : Yup.object({



                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let group = state.groups.selected.at(0);
                        if (!group) {
                            actions.setFieldError("group", "")
                            return;
                        }
                        req.setGroupid(group.id);
                        req.setPrivilegedataid(props.renderObject?.privilegeData?.id);
                        let allowedData: any = {};
                        const enums: string[] = [];
                        values.enums.forEach((e: DynamicField) => {
                            enums.push(e.value.trim())
                        })
                        if (enums.length > 0) {
                            allowedData = { ...allowedData, enum: enums };
                        }

                        const blocked: string[] = [];
                        values.blocked.forEach((e: DynamicField) => {
                            blocked.push(e.value.trim())
                        })
                        if (blocked.length > 0) {
                            allowedData = { ...allowedData, blocked: blocked };
                        }
                        const range: { from: string, to: string }[] = [];
                        values.range.forEach((e: DynamicField) => {
                            range.push({ from: e.label?.trim() ?? "0", to: e.value.trim() })
                        })
                        if (range.length > 0) {
                            allowedData = { ...allowedData, range: range };
                        }
                        if (values.regex && values.regex?.trim().length > 0) {
                            allowedData = { ...allowedData, regex: values.regex?.trim() ?? "" };
                        }
                        try {
                            const wrapper = new StringValue();
                            wrapper.setValue(JSON.stringify(allowedData));
                            req.setAlloweddata(wrapper);
                        } catch {
                            actions.setFieldError("regex", "")
                            return;
                        }
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddDataRestrictionResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {

                                        props?.onSuccess({
                                            id: res.success?.datarestrictionid,
                                            groupId: group.id,
                                            groupName: group?.name ?? "",
                                            enum: values.enums,
                                            blocked: values.blocked,
                                            range: values.range,
                                            regex: values.regex,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id);
                        let allowedData: any = {};
                        const enums: string[] = [];
                        values.enums.forEach((e: DynamicField) => {
                            enums.push(e.value.trim())
                        })
                        if (enums.length > 0) {
                            allowedData = { ...allowedData, enum: enums };
                        }
                        const blocked: string[] = [];
                        values.blocked.forEach((e: DynamicField) => {
                            blocked.push(e.value.trim())
                        })
                        if (blocked.length > 0) {
                            allowedData = { ...allowedData, blocked: blocked };
                        }
                        const range: { from: string, to: string }[] = [];
                        values.range.forEach((e: DynamicField) => {
                            range.push({ from: e.label?.trim() ?? "0", to: e.value.trim() })
                        })
                        if (range.length > 0) {
                            allowedData = { ...allowedData, range: range };
                        }
                        if (values.regex && values.regex?.trim().length > 0) {
                            allowedData = { ...allowedData, regex: values.regex?.trim() ?? "" };
                        }
                        try {
                            const wrapper = new StringValue();
                            wrapper.setValue(JSON.stringify(allowedData));
                            updateReq.setAlloweddata(wrapper);
                        } catch {
                            actions.setFieldError("regex", "")
                            return;
                        }
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateDataRestrictionResponse.AsObject) => {
                                if (res) {

                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            allowedData: allowedData,
                                            enum: values.enums,
                                            blocked: values.blocked,
                                            range: values.range,
                                            regex: values.regex,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            {props.type == FormType.ADD ? <Stack grow><Label required disabled={state.isLoading}>{t("affectedGroup")}</Label>
                                <List
                                    inputProps={{ placeholder: t("affectedGroup"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('groups')}
                                    isLoading={state.groups.items.length == 0 ? state.groups.isFetching : false}
                                    isSearching={state.groups.items.length > 0 ? state.groups.isFetching : false}
                                    moreSuggestionsAvailable={state.groups.hasMore && !state.groups.isFetching}
                                    suggestions={state.groups.items.length > 0 ? state.groups.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.groups.items.length > 0 && !state.groups.isFetching) {
                                            groupReq.setNextto(state.groups.items.at(state.groups.items.length - 1).id)
                                            getGroupsPromise = dispatch(getGroups({ body: groupReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.groups.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedGroup([state.groups.items.at(u)]))
                                        }

                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.groups.selected.length > 0 ? state.groups.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedGroup([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetGroups())
                                        groupReq.setSearch(undefined)
                                        groupReq.setNextto(0)
                                        dispatch(setIsFilteredSet(false))
                                        getGroupsPromise?.abort()
                                        getGroupsPromise = dispatch(getGroups({ body: groupReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onGroupFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                /></Stack> : undefined}
                            {props.renderObject?.privilegeData?.dataType ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("enumColumn") + ' '}
                                        <TooltipHost content={t("enumDescription")}>
                                            <Icon iconName="Info" aria-label={t("enumColumn")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <FieldArray
                                    name="enums"
                                >
                                    {(arrayHelpers) => {
                                        let r = [] as React.ReactNode[];
                                        if (formkikProps.values.enums && formkikProps.values.enums.length > 0) {
                                            r = formkikProps.values.enums.map((o, index) => {
                                                if (o.type == 1) {
                                                    return (<Stack horizontal key={index}>
                                                        {o.label != undefined ? <DynamicInputField name={`enums[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                            maxLength={500} /> : null}
                                                        <DynamicInputField name={`enums[${index}].value`} placeholder={t("enum")} disabled={state.isLoading}
                                                            maxLength={500} autoFocus={(o as any)?.autoFoucse}
                                                        />

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack>)
                                                }
                                            }
                                            )
                                        }
                                        if (formkikProps.values.enums.length <= 10) {
                                            r.push(< IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.enums.length + 1) + "", label: undefined, type: 1, value: "", autoFoucse: true })
                                                }} />)
                                        }
                                        return r;
                                    }

                                    }

                                </FieldArray></Stack>
                                : undefined}

                            {props.renderObject?.privilegeData?.dataType ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("blockedColumn") + ' '}
                                        <TooltipHost content={t("blockedDescription")}>
                                            <Icon iconName="Info" aria-label={t("blockedColumn")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <FieldArray
                                    name="blocked"
                                >
                                    {(arrayHelpers) => {
                                        let r = [] as React.ReactNode[];
                                        if (formkikProps.values.blocked && formkikProps.values.blocked.length > 0) {
                                            r = formkikProps.values.blocked.map((o, index) => {
                                                if (o.type == 1) {
                                                    return (<Stack horizontal key={index}>
                                                        {o.label != undefined ? <DynamicInputField name={`blocked[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                            maxLength={500} /> : null}
                                                        <DynamicInputField name={`blocked[${index}].value`} placeholder={t("blocked")} disabled={state.isLoading}
                                                            maxLength={500} autoFocus={(o as any)?.autoFoucse}
                                                        />

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack>)
                                                }
                                            }
                                            )
                                        }
                                        if (formkikProps.values.blocked.length <= 10) {
                                            r.push(< IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.blocked.length + 1) + "", label: undefined, type: 1, value: "", autoFoucse: true })
                                                }} />)
                                        }
                                        return r;
                                    }

                                    }

                                </FieldArray></Stack>
                                : undefined}

                            {props.renderObject?.privilegeData?.dataType ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("rangeColumn") + ' '}
                                        <TooltipHost content={t("rangeDescription")}>
                                            <Icon iconName="Info" aria-label={t("rangeColumn")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <FieldArray
                                    name="range"
                                >
                                    {arrayHelpers => {
                                        let r = [] as React.ReactNode[];
                                        if (formkikProps.values.range && formkikProps.values.range.length > 0) {
                                            r = formkikProps.values.range.map((o, index) => {
                                                if (o.type == 1) {
                                                    return (<Stack horizontal key={index}>
                                                        {o.label != undefined ? <DynamicInputField name={`range[${index}].label`} placeholder={t("from")} disabled={state.isLoading}
                                                            maxLength={200} autoFocus={(o as any)?.autoFoucse} /> : null}
                                                        <DynamicInputField name={`range[${index}].value`} placeholder={t("to")} disabled={state.isLoading}
                                                            maxLength={200}
                                                        />

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack>)
                                                }
                                            }
                                            )
                                        }
                                        if (formkikProps.values.range.length <= 10) {
                                            r.push(< IconButton disabled={state.isLoading}
                                                key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.range.length + 1) + "", label: "", type: 1, value: "", autoFoucse: true })
                                                }} />)
                                        }
                                        return r;
                                    }
                                    }

                                </FieldArray> </Stack>
                                : undefined}
                            {props.renderObject?.privilegeData?.dataType ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("regexColumn") + ' '}
                                        <TooltipHost content={t("regexDescription")}>
                                            <Icon iconName="Info" aria-label={t("regexColumn")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <Field name="regex" placeholder={t("regexColumn")} component={InputField} disabled={state.isLoading} maxLength={1000} />
                            </Stack> : undefined}
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </>
    );

}

