import { getTheme, IconButton, IModalStyleProps, IModalStyles, IStyleFunctionOrObject, Modal, Stack, Text } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/Hooks";
import { getMainTheme, ThemeManager } from "../../../app/Themes";
import { contentStyles, iconButtonStyles } from "./Popup.styles";

interface PopupProps {
    title: string,
    children: React.ReactNode,
    isOpen: boolean,
    isBlocking?: boolean
    onDismiss: () => void
}

export const Popup: React.FunctionComponent<PopupProps> = (props) => {
    const titleId = useId('title');
    const { t, i18n } = useTranslation();

    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })
    return (
        <Modal
            titleAriaId={titleId}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            isBlocking={props.isBlocking}
            containerClassName={contentStyles.container}

        >
            <Stack horizontal horizontalAlign="space-between" className={contentStyles.header} styles={{ root: { borderTopColor: getMainTheme(stateTheme.appTheme, stateTheme.isDarkModeEnabled).palette.themePrimary } }}>
                <Text variant={'xLarge'} id={titleId}>{props.title}</Text>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel={t("closePopup")}
                    onClick={props.onDismiss}
                />

            </Stack>

            <Stack className={contentStyles.body}>
                {props.children}
            </Stack>

        </Modal>

    );

}