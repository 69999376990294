/**
 * @fileoverview gRPC-Web generated client stub for privilege_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as privilege_pb from './privilege_pb';


export class PrivilegeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/privilege_endpoint.PrivilegeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    privilege_pb.AddPrivilegeRequest,
    privilege_pb.AddPrivilegeResponse,
    (request: privilege_pb.AddPrivilegeRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.AddPrivilegeResponse.deserializeBinary
  );

  add(
    request: privilege_pb.AddPrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.AddPrivilegeResponse>;

  add(
    request: privilege_pb.AddPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.AddPrivilegeResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.AddPrivilegeResponse>;

  add(
    request: privilege_pb.AddPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.AddPrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_endpoint.PrivilegeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_endpoint.PrivilegeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/privilege_endpoint.PrivilegeEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    privilege_pb.UpdatePrivilegeRequest,
    privilege_pb.UpdatePrivilegeResponse,
    (request: privilege_pb.UpdatePrivilegeRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.UpdatePrivilegeResponse.deserializeBinary
  );

  update(
    request: privilege_pb.UpdatePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.UpdatePrivilegeResponse>;

  update(
    request: privilege_pb.UpdatePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.UpdatePrivilegeResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.UpdatePrivilegeResponse>;

  update(
    request: privilege_pb.UpdatePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.UpdatePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_endpoint.PrivilegeEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_endpoint.PrivilegeEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateAvailability = new grpcWeb.MethodDescriptor(
    '/privilege_endpoint.PrivilegeEndpoint/UpdateAvailability',
    grpcWeb.MethodType.UNARY,
    privilege_pb.UpdatePrivilegeAvailabilityRequest,
    privilege_pb.UpdatePrivilegeAvailabilityResponse,
    (request: privilege_pb.UpdatePrivilegeAvailabilityRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.UpdatePrivilegeAvailabilityResponse.deserializeBinary
  );

  updateAvailability(
    request: privilege_pb.UpdatePrivilegeAvailabilityRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.UpdatePrivilegeAvailabilityResponse>;

  updateAvailability(
    request: privilege_pb.UpdatePrivilegeAvailabilityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.UpdatePrivilegeAvailabilityResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.UpdatePrivilegeAvailabilityResponse>;

  updateAvailability(
    request: privilege_pb.UpdatePrivilegeAvailabilityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.UpdatePrivilegeAvailabilityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_endpoint.PrivilegeEndpoint/UpdateAvailability',
        request,
        metadata || {},
        this.methodDescriptorUpdateAvailability,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_endpoint.PrivilegeEndpoint/UpdateAvailability',
    request,
    metadata || {},
    this.methodDescriptorUpdateAvailability);
  }

  methodDescriptorGetPrivileges = new grpcWeb.MethodDescriptor(
    '/privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
    grpcWeb.MethodType.UNARY,
    privilege_pb.GetPrivilegesRequest,
    privilege_pb.GetPrivilegesResponse,
    (request: privilege_pb.GetPrivilegesRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.GetPrivilegesResponse.deserializeBinary
  );

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegesResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: privilege_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
        request,
        metadata || {},
        this.methodDescriptorGetPrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_endpoint.PrivilegeEndpoint/GetPrivileges',
    request,
    metadata || {},
    this.methodDescriptorGetPrivileges);
  }

  methodDescriptorGetPrivilege = new grpcWeb.MethodDescriptor(
    '/privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
    grpcWeb.MethodType.UNARY,
    privilege_pb.GetPrivilegeRequest,
    privilege_pb.GetPrivilegeResponse,
    (request: privilege_pb.GetPrivilegeRequest) => {
      return request.serializeBinary();
    },
    privilege_pb.GetPrivilegeResponse.deserializeBinary
  );

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilege_pb.GetPrivilegeResponse>;

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegeResponse) => void): grpcWeb.ClientReadableStream<privilege_pb.GetPrivilegeResponse>;

  getPrivilege(
    request: privilege_pb.GetPrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilege_pb.GetPrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilege,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_endpoint.PrivilegeEndpoint/GetPrivilege',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilege);
  }

}

