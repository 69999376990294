import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GroupEndpointClient } from '../../../../repository/UserManagement/GroupServiceClientPb';
import { GetGroupsRequest, GetGroupsResponse, DeleteGroupResponse, DeleteGroupRequest } from '../../../../repository/UserManagement/group_pb';


export interface GroupsPageState {
    groups: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
}

const initialState: GroupsPageState = {
    groups: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: []

}

const clientGroup = new GroupEndpointClient(API_URL, null, null);


export const getGroups = createAsyncThunk<GetGroupsResponse.AsObject, APIRequest<GetGroupsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groups/getGroups',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientGroup.getGroups(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const deleteGroup = createAsyncThunk<APIResponse<DeleteGroupResponse.AsObject>, APIRequest<DeleteGroupRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/groups/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await clientGroup.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


const getFilters = (state: GroupsPageState): any[] => {

    return [];

}

export const managersGroupsPageSlice = createSlice({
    name: 'pages/management/groups',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.groups = initialState.groups;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
        },
        resetGroups: (state) => {
            state.groups.items = [];
            state.groups.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.groups.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.groups.numberOfResults = action.payload;
        },
        setSelectedGroup: (state, action: PayloadAction<any[]>) => {
            state.groups.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.groups.isFilteredSet = action.payload;
        },

        addGroup: (state, action: PayloadAction<any>) => {
            if (!state.groups.isFilteredSet) {
                if (!state.groups.hasMore && !state.groups.isDescending) {
                    state.groups.items.push(action.payload);
                } else if (state.groups.isDescending) {
                    state.groups.items.unshift(action.payload);
                }
            }
        },
        updateGroup: (state, action: PayloadAction<any>) => {
            let selected = state.groups.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.groups.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.groups.items[u] = action?.payload
                }
            }

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getGroups.fulfilled, (state, { payload }) => {
            state.groups.isFetching = false
            if (payload) {
                var r = (payload?.success?.groupsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        description: val.description?.value ?? "",
                        groupCategory: val.groupcategory,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.groups.items.length != 0) {
                    l = state.groups.items.slice(0, state.groups.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.groups.numberOfResults) {
                    state.groups.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.groups.items = l
                //console.log(state.items)

            } else {
                state.groups.hasMore = false;
            }
        })
        builder.addCase(getGroups.rejected, (state, action) => {
            state.groups.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getGroups.pending, (state, action) => {
            state.groups.isFetching = true;
            state.message = undefined;
        })



        builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.groups.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.groups.selected = []
                    }
                    var u = state.groups.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.groups.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteGroup.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteGroup.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, resetGroups, setSelectedGroup, setSearchText, setIsFilteredSet, addGroup, updateGroup } = managersGroupsPageSlice.actions

export default managersGroupsPageSlice.reducer


