import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { GetRolesRequest, GetRolesResponse , GetUserTypesRequest, GetUserTypesResponse, GetOrganizationRequest, GetOrganizationResponse} from '../../../../repository/UserManagement/organization_pb';
import { AddUserRequest, AddUserResponse, GetUserRequest, GetUserResponse, UpdateAccountSecurityRequest, UpdateAccountSecurityResponse, UpdateUsernameRequest, UpdateUsernameResponse, UpdateUserRequest, UpdateUserResponse } from '../../../../repository/UserManagement/organizationmember_pb';
import { OrganizationEndpointClient } from '../../../../repository/UserManagement/OrganizationServiceClientPb';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../common/Table/TableSate';
import { OrganizationMemberEndpointClient } from '../../../../repository/UserManagement/OrganizationmemberServiceClientPb';

export interface UsersFormState {
  isLoading: boolean
  message?: ApiMessage
  roles: TableState
  userTypes: TableState
  stage1: any,
  stage2: any,
  stage3: any,
  currentOrganization?: OrganizationState,

}
export interface OrganizationState {
  id: number,
  name: string,
  domain: string
}
const initialState: UsersFormState = {
  isLoading: false,
  message: undefined,
  roles: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
  userTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
  stage1: undefined,
  stage2: undefined,
  stage3: undefined,
  currentOrganization: undefined
}

const client = new OrganizationMemberEndpointClient(API_URL, null, null);
const organizationClient = new OrganizationEndpointClient(API_URL, null, null);

export const add = createAsyncThunk<AddUserResponse.AsObject, APIRequest<AddUserRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/customers/users/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.add(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const update = createAsyncThunk<UpdateUserResponse.AsObject, APIRequest<UpdateUserRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/users/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const updateUsername = createAsyncThunk<UpdateUsernameResponse.AsObject, APIRequest<UpdateUsernameRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/users/updateUsername',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.updateUsername(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const updateAccountSecurity = createAsyncThunk<UpdateAccountSecurityResponse.AsObject, APIRequest<UpdateAccountSecurityRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/users/updateAccountSecurity',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.updateAccountSecurity(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)
export const getRoles = createAsyncThunk<GetRolesResponse.AsObject, APIRequest<GetRolesRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/users/getRoles',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {

        var r = await organizationClient.getRoles(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }


)

export const getOrganization = createAsyncThunk<APIResponse<GetOrganizationResponse.AsObject>, APIRequest<GetOrganizationRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/customers/users/getOrganization',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await organizationClient.getOrganization(req.body, req.headers ?? {});
        return { metadata: { id: req.body.getId() }, response: r.toObject() };
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const getManager = createAsyncThunk<APIResponse<GetUserResponse.AsObject>, APIRequest<GetUserRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/customers/users/get',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.getUser(req.body, req.headers ?? {});
        return { metadata: { id: req.body.getId() }, response: r.toObject() };
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const getUserTypes = createAsyncThunk<GetUserTypesResponse.AsObject, APIRequest<GetUserTypesRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/users/getUserTypes',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await organizationClient.getUserTypes(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }

)
export const usersFormSlice = createSlice({
  name: 'forms/customers/users',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;

    },
    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.roles = initialState.roles;
      state.userTypes = initialState.userTypes
      state.stage1 = initialState.stage1;
      state.stage2 = initialState.stage2;
      state.stage3 = initialState.stage3;
      state.currentOrganization = initialState.currentOrganization;

    },
    resetRoles: (state) => {
      state.roles = initialState.roles;
    },
    resetUserTypes: (state) => {
      state.userTypes = initialState.userTypes
    },
    setSelectedRole: (state, action: PayloadAction<any[]>) => {
      state.roles.selected = action.payload;
    },
    setSelectedUserType: (state, action: PayloadAction<any[]>) => {
      state.userTypes.selected = action.payload;

    },
    setIsFilteredRolesSet: (state, action: PayloadAction<boolean>) => {
      state.roles.isFilteredSet = action.payload;
    },
    setIsFilteredUserTypesSet: (state, action: PayloadAction<boolean>) => {
      state.userTypes.isFilteredSet = action.payload;
    },
    setStage1: (state, action: PayloadAction<any>) => {
      let stage1 = {
        internalId: action.payload?.internalId,
        firstname: action.payload?.firstname ? action.payload?.firstname?.trim() : "",
        lastname: action.payload?.lastname ? action.payload?.lastname?.trim() : "",
        name: action.payload?.firstname?.trim() + " " + action.payload?.lastname?.trim(),
        phoneNumbers: action.payload?.phoneNumbers,
        emailAddresses: action.payload?.emailAddresses,
        addressCountry: action.payload?.addressCountry,
        addressCity: action.payload?.addressCity,
        addressFirstLine: action.payload?.addressFirstLine,
        addressSecondLine: action.payload?.addressSecondLine,
        addressPostalCode: action.payload?.addressPostalCode,
        isConfirmed: action.payload?.isConfirmed,
        createdDate: action.payload?.createdDate,
        addedBy: action.payload?.addedBy,
        addedById: action.payload?.addedById,
        lastlogin: action.payload?.lastlogin,
      }
      state.stage1 = stage1;

    },
    setStage2: (state, action: PayloadAction<any>) => {

      let stage2 = {
        username: action.payload?.username,
        newPassword: action.payload?.newPassword,
        rePassword: action.payload?.rePassword,
        isActive: action.payload?.isActive,
        primaryEmail: action.payload?.primaryEmail,
        primaryPhoneNumber: action.payload?.primaryPhoneNumber,
        usertype: action.payload?.usertype,
        usertypeId: action.payload?.usertypeId,
        role: action.payload?.role,
        roleId: action.payload?.roleId,
        isEmailTFEnabled: action.payload?.isEmailTFEnabled,
        isAuthAppTFEnabled: action.payload?.isAuthAppTFEnabled,
        isPhoneTFEnabled: action.payload?.isPhoneTFEnabled,
        TFEmail: action.payload?.TFEmail,
        TFPhone: action.payload?.TFPhone,

      }
      state.stage2 = stage2


    },
    setStage3: (state, action: PayloadAction<any>) => {
      let stage3 = {
        extraFields: action.payload?.extraFields
      }
      state.stage3 = stage3;
    },
    setCurrentOrganization: (state, action: PayloadAction<OrganizationState>) => {
      state.currentOrganization = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

    
    builder.addCase(updateUsername.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(updateUsername.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })

    
    builder.addCase(updateUsername.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

    builder.addCase(updateAccountSecurity.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(updateAccountSecurity.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })

    
    builder.addCase(updateAccountSecurity.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    

    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      state.roles.isFetching = false;
      if (payload) {
        var r = payload?.success?.rolesList.map(val => {
          return {
            id: val.id,
            name: val.name?.value ?? "",
            isdefault: val.isdefault,
            rolecategory: val.rolecategory,
            createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
            addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
            addedById: val.addedby?.id?.value,
          }
        }
        ) as any[]

        if (r.length < state.roles.numberOfResults) {
          state.roles.hasMore = false;
        }
        state.roles.items = state.roles.items.concat(r);

      }
    })
    builder.addCase(getRoles.rejected, (state, action) => {
      state.roles.isFetching = false;

      if (action.payload) {
        //state.message = action.payload;
      }
    })
    builder.addCase(getRoles.pending, (state, action) => {
      state.roles.isFetching = true;
    //  state.message = undefined;
    })

    builder.addCase(getUserTypes.fulfilled, (state, { payload }) => {
      state.userTypes.isFetching = false;
      if (payload) {
        var r = payload?.success?.usertypesList.map(val => {
          return {
            id: val.id,
            name: val.name?.value ?? "",
            userTypeCategory: val.usertypecategory,
            createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
            addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
            addedById: val.addedby?.id?.value,
          }
        }
        ) as any[]

        if (r.length < state.userTypes.numberOfResults) {
          state.userTypes.hasMore = false;
        }
        state.userTypes.items = state.userTypes.items.concat(r);

      }
    })
    builder.addCase(getUserTypes.rejected, (state, action) => {
      state.userTypes.isFetching = false;
      if (action.payload) {
        //state.message = action.payload;
      }
    })
    builder.addCase(getUserTypes.pending, (state, action) => {
      state.userTypes.isFetching = true;
     // state.message = undefined;
    })
    builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.currentOrganization = { id: payload.metadata?.id, domain: payload.response.success?.domain?.value ?? "", name: payload.response.success?.name?.value ?? "" }
      }
    })
    builder.addCase(getOrganization.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(getOrganization.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

    builder.addCase(getManager.fulfilled, (state, { payload }) => {
      if (payload) {
        let stage1 = {
          internalId: payload.response?.success?.profile?.internalid,
          firstname: payload.response?.success?.profile?.firstname ? payload.response?.success?.profile?.firstname?.value?.trim() : "",
          lastname: payload.response?.success?.profile?.lastname ? payload.response?.success?.profile?.lastname?.value?.trim() : "",
          phoneNumbers: [] as DynamicField[],
          emailAddresses: [] as DynamicField[],
          addressCountry: payload.response?.success?.profile?.address ? { key: payload.response?.success?.profile?.address?.countryiso?.value, text: '' } : { key: 'LY', text: 'Libya' },
          addressCity: payload.response?.success?.profile?.address ? payload.response?.success?.profile?.address?.city?.value : "",
          addressFirstLine: payload.response?.success?.profile?.address ? payload.response?.success?.profile?.address?.firstline?.value : "",
          addressSecondLine: payload.response?.success?.profile?.address ? payload.response?.success?.profile?.address?.secondline?.value : "",
          addressPostalCode: payload.response?.success?.profile?.address ? payload.response?.success?.profile?.address?.postalcode?.value : "",
          isConfirmed: payload.response?.success?.isconfirmed?.value,
          createdDate: payload.response?.success?.profile?.createddate ? formatDate(timestampToDate(payload.response?.success?.profile?.createddate?.seconds, payload.response?.success?.profile?.createddate?.nanos)) : "",
          addedBy: payload.response?.success?.profile?.addedby ? (payload.response?.success?.profile?.addedby?.firstname?.value ?? "") + " " + (payload.response?.success?.profile?.addedby?.lastname?.value ?? "") : "",
          addedById: payload.response?.success?.profile?.addedby?.id?.value,
          lastlogin: payload.response?.success?.lastlogin ? formatDate(timestampToDate(payload.response?.success?.lastlogin?.seconds, payload.response?.success?.lastlogin?.nanos)) : "",
        }

        if (payload.response?.success?.profile?.phonenumbersList) {
          let count = 0
          payload.response?.success?.profile?.phonenumbersList?.forEach(element => {
            stage1.phoneNumbers.push({ key: count + "", label: undefined, type: 1, value: element.value })
            count++
          });
        }

        if (payload.response?.success?.profile?.emailaddressesList) {
          let count = 0
          payload.response?.success?.profile?.emailaddressesList?.forEach(element => {
            stage1.emailAddresses.push({ key: count + "", label: undefined, type: 1, value: element.value })
            count++
          });
        }
        state.stage1 = stage1;

        let stage2 = {
          username: payload.response?.success?.username ? payload.response?.success?.username?.value?.trim() : "",
          isActive: payload.response?.success?.isactive?.value,
          primaryEmail: (payload.response?.success?.accountemail) ? payload.response?.success?.accountemail?.value : '',
          primaryPhoneNumber: (payload.response?.success?.accountphonenumber) ? payload.response?.success?.accountphonenumber?.value : '',
          usertype: payload?.response.success?.profile?.usertype ? payload?.response.success?.profile?.usertype.name?.value : "",
          usertypeId: payload?.response.success?.profile?.usertype?.id,
          role: payload?.response.success?.profile?.role ? payload?.response.success?.profile?.role.name?.value : "",
          roleId: payload?.response.success?.profile?.role?.id,
          isEmailTFEnabled: payload?.response.success?.accountsecurity?.isemailtfenabled?.value,
          isAuthAppTFEnabled: payload?.response.success?.accountsecurity?.isauthapptfenabled?.value,
          isPhoneTFEnabled: payload?.response.success?.accountsecurity?.isphonetfenabled?.value,
          TFEmail: payload?.response.success?.accountsecurity?.tfemail?.value,
          TFPhone: payload?.response.success?.accountsecurity?.tfphonenumber?.value,

        }
        state.stage2 = stage2

        let stage3 = {
          extraFields: [] as DynamicField[]
        }
        if (payload.response?.success?.profile?.extrafieldsList) {
          let count = 0
          payload.response?.success?.profile?.extrafieldsList?.forEach(element => {
            stage3.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
            count++
          });
        }

        state.stage3 = stage3
      }

      state.isLoading = false;

    })
    builder.addCase(getManager.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(getManager.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

  }
})

export const { setStage1, setStage2, setStage3, reset, dismissMessage, resetRoles, resetUserTypes, setSelectedRole, setSelectedUserType, setIsFilteredRolesSet, setIsFilteredUserTypesSet, setIsLoading, setCurrentOrganization } = usersFormSlice.actions

export default usersFormSlice.reducer


