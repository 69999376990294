/**
 * @fileoverview gRPC-Web generated client stub for role_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as role_pb from './role_pb';


export class RoleEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/role_endpoint.RoleEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    role_pb.AddRoleRequest,
    role_pb.AddRoleResponse,
    (request: role_pb.AddRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.AddRoleResponse.deserializeBinary
  );

  add(
    request: role_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<role_pb.AddRoleResponse>;

  add(
    request: role_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.AddRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.AddRoleResponse>;

  add(
    request: role_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.AddRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/role_endpoint.RoleEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/role_endpoint.RoleEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/role_endpoint.RoleEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    role_pb.UpdateRoleRequest,
    role_pb.UpdateRoleResponse,
    (request: role_pb.UpdateRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.UpdateRoleResponse.deserializeBinary
  );

  update(
    request: role_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<role_pb.UpdateRoleResponse>;

  update(
    request: role_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.UpdateRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.UpdateRoleResponse>;

  update(
    request: role_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.UpdateRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/role_endpoint.RoleEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/role_endpoint.RoleEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/role_endpoint.RoleEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    role_pb.DeleteRoleRequest,
    role_pb.DeleteRoleResponse,
    (request: role_pb.DeleteRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.DeleteRoleResponse.deserializeBinary
  );

  delete(
    request: role_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<role_pb.DeleteRoleResponse>;

  delete(
    request: role_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.DeleteRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.DeleteRoleResponse>;

  delete(
    request: role_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.DeleteRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/role_endpoint.RoleEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/role_endpoint.RoleEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetRoles = new grpcWeb.MethodDescriptor(
    '/role_endpoint.RoleEndpoint/GetRoles',
    grpcWeb.MethodType.UNARY,
    role_pb.GetRolesRequest,
    role_pb.GetRolesResponse,
    (request: role_pb.GetRolesRequest) => {
      return request.serializeBinary();
    },
    role_pb.GetRolesResponse.deserializeBinary
  );

  getRoles(
    request: role_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<role_pb.GetRolesResponse>;

  getRoles(
    request: role_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.GetRolesResponse) => void): grpcWeb.ClientReadableStream<role_pb.GetRolesResponse>;

  getRoles(
    request: role_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.GetRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/role_endpoint.RoleEndpoint/GetRoles',
        request,
        metadata || {},
        this.methodDescriptorGetRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/role_endpoint.RoleEndpoint/GetRoles',
    request,
    metadata || {},
    this.methodDescriptorGetRoles);
  }

  methodDescriptorGetAvailablePrivileges = new grpcWeb.MethodDescriptor(
    '/role_endpoint.RoleEndpoint/GetAvailablePrivileges',
    grpcWeb.MethodType.UNARY,
    role_pb.GetAvailablePrivilegesRequest,
    role_pb.GetAvailablePrivilegesResponse,
    (request: role_pb.GetAvailablePrivilegesRequest) => {
      return request.serializeBinary();
    },
    role_pb.GetAvailablePrivilegesResponse.deserializeBinary
  );

  getAvailablePrivileges(
    request: role_pb.GetAvailablePrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<role_pb.GetAvailablePrivilegesResponse>;

  getAvailablePrivileges(
    request: role_pb.GetAvailablePrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.GetAvailablePrivilegesResponse) => void): grpcWeb.ClientReadableStream<role_pb.GetAvailablePrivilegesResponse>;

  getAvailablePrivileges(
    request: role_pb.GetAvailablePrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.GetAvailablePrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/role_endpoint.RoleEndpoint/GetAvailablePrivileges',
        request,
        metadata || {},
        this.methodDescriptorGetAvailablePrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/role_endpoint.RoleEndpoint/GetAvailablePrivileges',
    request,
    metadata || {},
    this.methodDescriptorGetAvailablePrivileges);
  }

}

