import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/UserServiceClientPb';
import { GetUsersRequest, ResetUserPasswordResponse, GetUsersResponse, GetUserResponse, UpdateUserActivityResponse, UpdateUserActivityRequest, UpdateUserConfirmationStateRequest, UpdateUserConfirmationStateResponse, GetUserRequest, ResetUserPasswordRequest } from '../../../../repository/UserManagement/user_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { GetRolesRequest, GetRolesResponse } from '../../../../repository/UserManagement/role_pb';
import { RoleEndpointClient } from '../../../../repository/UserManagement/RoleServiceClientPb';
import { UserTypeEndpointClient } from '../../../../repository/UserManagement/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../../repository/UserManagement/usertype_pb';
import { number } from 'yup';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';


export interface UsersPageState {
    managers: TableState
    roles: TableState
    userTypes: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    activityFilterOption: { key: number, text: string },
    confirmationFilterOption: { key: number, text: string },
    twoFactorFilterOption: { key: number, text: string },
    filters: any[],
    newPassword: string | undefined
}

const initialState: UsersPageState = {
    managers: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    roles: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    userTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    activityFilterOption: { key: 0, text: "" },
    confirmationFilterOption: { key: 0, text: "" },
    twoFactorFilterOption: { key: 0, text: "" },
    filters: [],
    newPassword: undefined
}

const client = new UserEndpointClient(API_URL, null, null);
const clientRole = new RoleEndpointClient(API_URL, null, null);
const clientuserType = new UserTypeEndpointClient(API_URL, null, null);

export const getManagerUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/management/users/getUsers',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getRoles = createAsyncThunk<GetRolesResponse.AsObject, APIRequest<GetRolesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/users/getRoles',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientRole.getRoles(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }


)

export const getUserTypes = createAsyncThunk<GetUserTypesResponse.AsObject, APIRequest<GetUserTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/users/getUserTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientuserType.getUserTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateStatus = createAsyncThunk<APIResponse<UpdateUserActivityResponse.AsObject>, APIRequest<UpdateUserActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/users/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsaccountactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateConfirmationStatus = createAsyncThunk<APIResponse<UpdateUserConfirmationStateResponse.AsObject>, APIRequest<UpdateUserConfirmationStateRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/users/updateConfirmationStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateConfirmationState(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsaccountconfirmed() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const resetPassword = createAsyncThunk<APIResponse<ResetUserPasswordResponse.AsObject>, APIRequest<ResetUserPasswordRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/users/resetPassword',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.resetUserPassword(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

const getFilters = (state: UsersPageState): any[] => {

    const selected = [];
    if (state.roles.selected.length > 0) {
        selected.push({ key: 1, name: state.roles.selected.at(0).name });
    }
    if (state.userTypes.selected.length > 0) {
        selected.push({ key: 2, name: state.userTypes.selected.at(0).name });
    }
    if (state.activityFilterOption.key != 0) {
        selected.push({ key: 3, name: state.activityFilterOption.text });
    }
    if (state.confirmationFilterOption.key != 0) {
        selected.push({ key: 4, name: state.confirmationFilterOption.text });
    }
    if (state.twoFactorFilterOption.key != 0) {
        selected.push({ key: 5, name: state.twoFactorFilterOption.text });
    }
    return selected;
}

export const managersUsersPageSlice = createSlice({
    name: 'pages/management/users',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.managers = initialState.managers;
            state.message = initialState.message;
            state.roles = initialState.roles;
            state.userTypes = initialState.userTypes;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.activityFilterOption = initialState.activityFilterOption;
            state.confirmationFilterOption = initialState.confirmationFilterOption;
            state.twoFactorFilterOption = initialState.twoFactorFilterOption;
            state.filters = initialState.filters
            state.newPassword = initialState.newPassword
        },
        resetManagers: (state) => {
            state.managers.items = [];
            state.managers.hasMore = true;
        },
        resetNewPassword: (state) => {
            state.newPassword = initialState.newPassword
        },
        resetRoles: (state) => {
            state.roles.items = [];
            state.roles.hasMore = true;
        },
        resetUserTypes: (state) => {
            state.userTypes.items = [];
            state.userTypes.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.managers.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.managers.numberOfResults = action.payload;
        },
        setSelectedManager: (state, action: PayloadAction<any[]>) => {
            state.managers.selected = action.payload;
        },
        setSelectedRole: (state, action: PayloadAction<any[]>) => {
            state.roles.selected = action.payload;
            state.filters = getFilters(state);
        },
        setSelectedUserType: (state, action: PayloadAction<any[]>) => {
            state.userTypes.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;

        },
        setActivityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.activityFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setConfirmationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.confirmationFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setTwoFactorFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.twoFactorFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setIsFilteredManagersSet: (state, action: PayloadAction<boolean>) => {
            state.managers.isFilteredSet = action.payload;
        },
        setIsFilteredRolesSet: (state, action: PayloadAction<boolean>) => {
            state.roles.isFilteredSet = action.payload;
        },
        setIsFilteredUserTypesSet: (state, action: PayloadAction<boolean>) => {
            state.userTypes.isFilteredSet = action.payload;
        },

        addUser: (state, action: PayloadAction<any>) => {
            if (!state.managers.isFilteredSet) {
                if (!state.managers.hasMore && !state.managers.isDescending) {
                    state.managers.items.push(action.payload);
                } else if (state.managers.isDescending) {
                    state.managers.items.unshift(action.payload);
                }
            }
        },
        updateUser: (state, action: PayloadAction<any>) => {
            let selected = state.managers.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).internalId == action?.payload?.internalId) {
                    selected[0] = action?.payload
                }
                var u = state.managers.items.findIndex(e => e.internalId == action?.payload?.internalId)
                if (u >= 0) {
                    state.managers.items[u] = action?.payload
                }
            }
        },
        updateUsername: (state, action: PayloadAction<any>) => {
            let selected = state.managers.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).internalId == action?.payload?.internalId) {
                    selected[0].username = action?.payload?.username
                }
                var u = state.managers.items.findIndex(e => e.internalId == action?.payload?.internalId)
                if (u >= 0) {
                    state.managers.items[u].username = action?.payload?.username
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getManagerUsers.fulfilled, (state, { payload }) => {
            state.managers.isFetching = false
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.id,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.managers.items.length != 0) {
                    l = state.managers.items.slice(0, state.managers.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.managers.numberOfResults) {
                    state.managers.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.managers.items = l
                //console.log(state.items)

            } else {
                state.managers.hasMore = false;
            }
        })
        builder.addCase(getManagerUsers.rejected, (state, action) => {
            state.managers.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getManagerUsers.pending, (state, action) => {
            state.managers.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(getRoles.fulfilled, (state, { payload }) => {
            state.roles.isFetching = false;
            if (payload) {
                var r = payload?.success?.rolesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isdefault: val.isdefault,
                        rolecategory: val.rolecategory,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[]

                if (r.length < state.roles.numberOfResults) {
                    state.roles.hasMore = false;
                }
                state.roles.items = state.roles.items.concat(r);

            }
        })
        builder.addCase(getRoles.rejected, (state, action) => {
            state.roles.isFetching = false;

            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getRoles.pending, (state, action) => {
            state.roles.isFetching = true;
           // state.message = undefined;
        })

        builder.addCase(getUserTypes.fulfilled, (state, { payload }) => {
            state.userTypes.isFetching = false;
            if (payload) {
                var r = payload?.success?.usertypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        userTypeCategory: val.usertypecategory,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[]

                if (r.length < state.userTypes.numberOfResults) {
                    state.userTypes.hasMore = false;
                }
                state.userTypes.items = state.userTypes.items.concat(r);

            }
        })
        builder.addCase(getUserTypes.rejected, (state, action) => {
            state.userTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getUserTypes.pending, (state, action) => {
            state.userTypes.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.managers.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.managers.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.managers.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateConfirmationStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.managers.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).isConfirmed = payload?.metadata.state
                    }
                    var u = state.managers.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.managers.items.at(u).isConfirmed = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateConfirmationStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateConfirmationStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


      

        builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.newPassword = payload?.response.success?.newpassword?.value
            }
        })
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(resetPassword.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const {resetNewPassword, updateUsername, setIsFilteredManagersSet, setIsFilteredRolesSet, setIsFilteredUserTypesSet, reset, dismissMessage, setSort, setNumberOfResults, resetRoles, resetManagers, setSelectedManager, resetUserTypes, setSelectedRole, setSelectedUserType, setSearchText, setActivityFilterOption, setConfirmationFilterOption, setTwoFactorFilterOption, addUser, updateUser } = managersUsersPageSlice.actions

export default managersUsersPageSlice.reducer


