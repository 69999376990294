import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, DocumentCard, DocumentCardPreview, DocumentCardTitle, DocumentCardActivity, IDocumentCardPreviewProps, ImageFit, DocumentCardLogo, IDocumentCardLogoProps, IDocumentCardStyles, mergeStyles } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/Hooks"
import { getPage } from "../../..//app/Pages"
import { setPage } from "../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../common/Table/Table"
import { ApiMessage, clearSession, getHeaders, getSession } from "../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../app/Helpers"
import { TableState } from "../../common/Table/TableSate"
import { Message } from "../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../common/Popup/Popup"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../PageProps"
import { List } from "../../common/List/List"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import * as Yup from 'yup';



let org: number = -1;

const key = "dashboard"
export const DashboardPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [name, setName] = useState("");

    const state: {
        appTheme: string, isDarkModeEnabled: boolean,

    } = useAppSelector((state) => {
        return {
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,

        }
    })



    useEffect(() => {
        let current = getSession();

        if (!current) {
            clearSession();
            navigate("/login");
        }
        setName((current.profile?.firstname?.value ?? " ") + " " + (current.profile?.lastname?.value ?? " "))


        var l = searchParams.get('org');

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
            }
        }

        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        return () => { //clean up
        }
    }, [])



    const logoProps: IDocumentCardLogoProps = {
        logoIcon: 'OutlookLogo',
    };

    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, width: 320, textAlign: "justify" },
    };
    const conversationTileClass = mergeStyles({ height: 210 });
    const handleNavClick = (event?: React.SyntheticEvent<HTMLElement>, url?: string) => {
        if (event) {
            event.preventDefault();
            navigate(url + ((org == -1) ? "" : "?org=" + org));
        }
    }
    return (
        <Stack tokens={{ padding: "20px", childrenGap: 20 }}>
            <Stack.Item tokens={{ margin: "0px 0px 0px 20px" }}>
                <Text variant={"large"} block><b>{t("welcome", { name: name })}</b></Text>
            </Stack.Item>





            <Stack horizontal horizontalAlign="center">
                <DocumentCard
                    aria-label={
                        t("managementDesc")
                    }
                    styles={cardStyles}
                    onClick={(e) => {
                        handleNavClick(e, org != -1 ?  "/customers/users"  : "/management/users")
                    }}

                >
                    <DocumentCardLogo logoIcon="Admin" />
                    <div className={conversationTileClass}>
                        <DocumentCardTitle title={t("management")} shouldTruncate />
                        <DocumentCardTitle
                            title={org != -1 ? t("orgManagementDesc") : t("managementDesc")}

                            showAsSecondaryTitle
                        />
                    </div>
                </DocumentCard>

                {org != -1 ?
                    <DocumentCard
                        aria-label={
                            t("customersDesc")
                        }
                        styles={cardStyles}
                        onClick={(e) => {
                            handleNavClick(e, "/clients/users")
                        }}

                    >
                        <DocumentCardLogo logoIcon="People" />
                        <div className={conversationTileClass}>
                            <DocumentCardTitle title={t("customers")} shouldTruncate />
                            <DocumentCardTitle
                                title={t("customersDesc")}

                                showAsSecondaryTitle
                            />
                        </div>
                    </DocumentCard> : undefined}


                <DocumentCard
                    aria-label={
                        t("logsDesc")
                    }
                    styles={cardStyles}
                    onClick={(e) => {
                        handleNavClick(e, "/logs")
                    }}

                >
                    <DocumentCardLogo logoIcon="History" />
                    <div className={conversationTileClass}>
                        <DocumentCardTitle title={t("logs")} shouldTruncate />
                        <DocumentCardTitle
                            title={t("logsDesc")}

                            showAsSecondaryTitle
                        />
                    </div>
                </DocumentCard>
                <DocumentCard
                    aria-label={
                        t("organizationsDesc")
                    }
                    styles={cardStyles}
                    onClick={(e) => {
                        handleNavClick(e, "/organizations")
                    }}
                >
                    <DocumentCardLogo logoIcon="Org" />
                    <div className={conversationTileClass}>
                        <DocumentCardTitle title={t("organizations")} shouldTruncate />
                        <DocumentCardTitle
                            title={t("organizationsDesc")}
                            showAsSecondaryTitle
                        />
                    </div>
                </DocumentCard>

            </Stack>

        </Stack >



    );
}




