// source: privilegedata.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.privilege_data_endpoint.AddPrivilegeDataReplay', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.AddPrivilegeDataRequest', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.AddPrivilegeDataResponse', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.AddPrivilegeDataResponse.ResultCase', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.DaletePrivilegeDataRequest', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.DeletePrivilegeDataReplay', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.DeletePrivilegeDataResponse', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.DeletePrivilegeDataResponse.ResultCase', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataReplay', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataRequest', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataResponse', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.GetPrivilegeDataResponse.ResultCase', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.PrivilegeDataSummaryInfo', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.UpdatePrivilegeDataReplay', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.UpdatePrivilegeDataRequest', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.UpdatePrivilegeDataResponse', null, global);
goog.exportSymbol('proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.AddPrivilegeDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.AddPrivilegeDataRequest.displayName = 'proto.privilege_data_endpoint.AddPrivilegeDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.UpdatePrivilegeDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.displayName = 'proto.privilege_data_endpoint.UpdatePrivilegeDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.DaletePrivilegeDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.DaletePrivilegeDataRequest.displayName = 'proto.privilege_data_endpoint.DaletePrivilegeDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataRequest.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.AddPrivilegeDataReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.AddPrivilegeDataReplay.displayName = 'proto.privilege_data_endpoint.AddPrivilegeDataReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.UpdatePrivilegeDataReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.displayName = 'proto.privilege_data_endpoint.UpdatePrivilegeDataReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.DeletePrivilegeDataReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.DeletePrivilegeDataReplay.displayName = 'proto.privilege_data_endpoint.DeletePrivilegeDataReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.privilege_data_endpoint.GetPrivilegeDataReplay.repeatedFields_, null);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataReplay.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.PrivilegeDataSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.displayName = 'proto.privilege_data_endpoint.PrivilegeDataSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.privilege_data_endpoint.AddPrivilegeDataResponse.oneofGroups_);
};
goog.inherits(proto.privilege_data_endpoint.AddPrivilegeDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.AddPrivilegeDataResponse.displayName = 'proto.privilege_data_endpoint.AddPrivilegeDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.oneofGroups_);
};
goog.inherits(proto.privilege_data_endpoint.UpdatePrivilegeDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.displayName = 'proto.privilege_data_endpoint.UpdatePrivilegeDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.privilege_data_endpoint.DeletePrivilegeDataResponse.oneofGroups_);
};
goog.inherits(proto.privilege_data_endpoint.DeletePrivilegeDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.DeletePrivilegeDataResponse.displayName = 'proto.privilege_data_endpoint.DeletePrivilegeDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.privilege_data_endpoint.GetPrivilegeDataResponse.oneofGroups_);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataResponse.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.oneofGroups_);
};
goog.inherits(proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.displayName = 'proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.AddPrivilegeDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertytype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    datatype: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isrequired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    validation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    privilegeid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.AddPrivilegeDataRequest;
  return proto.privilege_data_endpoint.AddPrivilegeDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertytype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDatatype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrequired(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.AddPrivilegeDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertytype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDatatype();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsrequired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getValidation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrivilegeid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string propertyName = 3;
 * @return {string}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getPropertyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setPropertyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string propertyType = 4;
 * @return {string}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getPropertytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setPropertytype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool dataType = 5;
 * @return {boolean}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getDatatype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool isRequired = 6;
 * @return {boolean}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getIsrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setIsrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string validation = 7;
 * @return {string}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 privilegeId = 8;
 * @return {number}
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.getPrivilegeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataRequest.prototype.setPrivilegeid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertytype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    datatype: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isrequired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    validation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.UpdatePrivilegeDataRequest;
  return proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertytype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDatatype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrequired(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertytype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDatatype();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsrequired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getValidation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string propertyName = 3;
 * @return {string}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getPropertyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setPropertyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string propertyType = 4;
 * @return {string}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getPropertytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setPropertytype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool dataType = 5;
 * @return {boolean}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getDatatype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool isRequired = 6;
 * @return {boolean}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getIsrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setIsrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string validation = 7;
 * @return {string}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 id = 8;
 * @return {number}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.DaletePrivilegeDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest}
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.DaletePrivilegeDataRequest;
  return proto.privilege_data_endpoint.DaletePrivilegeDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest}
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.DaletePrivilegeDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.DaletePrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.DaletePrivilegeDataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    privilegeid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataRequest;
  return proto.privilege_data_endpoint.GetPrivilegeDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPrivilegeid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 nextTo = 3;
 * @return {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 privilegeId = 6;
 * @return {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.getPrivilegeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataRequest.prototype.setPrivilegeid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest;
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.AddPrivilegeDataReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegedataid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.AddPrivilegeDataReplay;
  return proto.privilege_data_endpoint.AddPrivilegeDataReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegedataid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.AddPrivilegeDataReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegedataid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 privilegeDataId = 1;
 * @return {number}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.getPrivilegedataid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.setPrivilegedataid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.AddPrivilegeDataReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.UpdatePrivilegeDataReplay;
  return proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.DeletePrivilegeDataReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.DeletePrivilegeDataReplay;
  return proto.privilege_data_endpoint.DeletePrivilegeDataReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.DeletePrivilegeDataReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegedataList: jspb.Message.toObjectList(msg.getPrivilegedataList(),
    proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataReplay;
  return proto.privilege_data_endpoint.GetPrivilegeDataReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.PrivilegeDataSummaryInfo;
      reader.readMessage(value,proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.deserializeBinaryFromReader);
      msg.addPrivilegedata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegedataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PrivilegeDataSummaryInfo privilegeData = 1;
 * @return {!Array<!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo>}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.getPrivilegedataList = function() {
  return /** @type{!Array<!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.privilege_data_endpoint.PrivilegeDataSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo>} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.setPrivilegedataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo}
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.addPrivilegedata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.privilege_data_endpoint.PrivilegeDataSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataReplay.prototype.clearPrivilegedataList = function() {
  return this.setPrivilegedataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    datatype: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    propertyname: (f = msg.getPropertyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.PrivilegeDataSummaryInfo;
  return proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDatatype(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertyname(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDatatype();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPropertyname();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProfileResponse addedBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
*/
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
*/
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool dataType = 4;
 * @return {boolean}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getDatatype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue PropertyName = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getPropertyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
*/
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setPropertyname = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.clearPropertyname = function() {
  return this.setPropertyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.hasPropertyname = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
*/
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.PrivilegeDataSummaryInfo} returns this
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.PrivilegeDataSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    datatype: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    propertyname: (f = msg.getPropertyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    propertytype: (f = msg.getPropertytype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isrequired: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    validation: (f = msg.getValidation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay;
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDatatype(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertyname(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertytype(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrequired(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValidation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDatatype();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPropertyname();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPropertytype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsrequired();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getValidation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProfileResponse addedBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool dataType = 4;
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getDatatype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue PropertyName = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getPropertyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setPropertyname = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearPropertyname = function() {
  return this.setPropertyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasPropertyname = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue propertyType = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getPropertytype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setPropertytype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearPropertytype = function() {
  return this.setPropertytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasPropertytype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool isRequired = 9;
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getIsrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setIsrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.StringValue validation = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.getValidation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.setValidation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.clearValidation = function() {
  return this.setValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.prototype.hasValidation = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.privilege_data_endpoint.AddPrivilegeDataResponse.ResultCase}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.getResultCase = function() {
  return /** @type {proto.privilege_data_endpoint.AddPrivilegeDataResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.privilege_data_endpoint.AddPrivilegeDataResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.AddPrivilegeDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.privilege_data_endpoint.AddPrivilegeDataReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataResponse}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.AddPrivilegeDataResponse;
  return proto.privilege_data_endpoint.AddPrivilegeDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataResponse}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.AddPrivilegeDataReplay;
      reader.readMessage(value,proto.privilege_data_endpoint.AddPrivilegeDataReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.AddPrivilegeDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.AddPrivilegeDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.privilege_data_endpoint.AddPrivilegeDataReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddPrivilegeDataReplay success = 1;
 * @return {?proto.privilege_data_endpoint.AddPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.getSuccess = function() {
  return /** @type{?proto.privilege_data_endpoint.AddPrivilegeDataReplay} */ (
    jspb.Message.getWrapperField(this, proto.privilege_data_endpoint.AddPrivilegeDataReplay, 1));
};


/**
 * @param {?proto.privilege_data_endpoint.AddPrivilegeDataReplay|undefined} value
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataResponse} returns this
*/
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.privilege_data_endpoint.AddPrivilegeDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.AddPrivilegeDataResponse} returns this
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.AddPrivilegeDataResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.ResultCase}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.getResultCase = function() {
  return /** @type {proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.UpdatePrivilegeDataResponse;
  return proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.UpdatePrivilegeDataReplay;
      reader.readMessage(value,proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.privilege_data_endpoint.UpdatePrivilegeDataReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdatePrivilegeDataReplay success = 1;
 * @return {?proto.privilege_data_endpoint.UpdatePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.getSuccess = function() {
  return /** @type{?proto.privilege_data_endpoint.UpdatePrivilegeDataReplay} */ (
    jspb.Message.getWrapperField(this, proto.privilege_data_endpoint.UpdatePrivilegeDataReplay, 1));
};


/**
 * @param {?proto.privilege_data_endpoint.UpdatePrivilegeDataReplay|undefined} value
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse} returns this
*/
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.UpdatePrivilegeDataResponse} returns this
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.UpdatePrivilegeDataResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.privilege_data_endpoint.DeletePrivilegeDataResponse.ResultCase}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.getResultCase = function() {
  return /** @type {proto.privilege_data_endpoint.DeletePrivilegeDataResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.privilege_data_endpoint.DeletePrivilegeDataResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.DeletePrivilegeDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.privilege_data_endpoint.DeletePrivilegeDataReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.DeletePrivilegeDataResponse;
  return proto.privilege_data_endpoint.DeletePrivilegeDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.DeletePrivilegeDataReplay;
      reader.readMessage(value,proto.privilege_data_endpoint.DeletePrivilegeDataReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.DeletePrivilegeDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.privilege_data_endpoint.DeletePrivilegeDataReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeletePrivilegeDataReplay success = 1;
 * @return {?proto.privilege_data_endpoint.DeletePrivilegeDataReplay}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.getSuccess = function() {
  return /** @type{?proto.privilege_data_endpoint.DeletePrivilegeDataReplay} */ (
    jspb.Message.getWrapperField(this, proto.privilege_data_endpoint.DeletePrivilegeDataReplay, 1));
};


/**
 * @param {?proto.privilege_data_endpoint.DeletePrivilegeDataReplay|undefined} value
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse} returns this
*/
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.privilege_data_endpoint.DeletePrivilegeDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.DeletePrivilegeDataResponse} returns this
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.DeletePrivilegeDataResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.privilege_data_endpoint.GetPrivilegeDataResponse.ResultCase}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.getResultCase = function() {
  return /** @type {proto.privilege_data_endpoint.GetPrivilegeDataResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.privilege_data_endpoint.GetPrivilegeDataResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.privilege_data_endpoint.GetPrivilegeDataReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataResponse}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataResponse;
  return proto.privilege_data_endpoint.GetPrivilegeDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataResponse}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.GetPrivilegeDataReplay;
      reader.readMessage(value,proto.privilege_data_endpoint.GetPrivilegeDataReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.privilege_data_endpoint.GetPrivilegeDataReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPrivilegeDataReplay success = 1;
 * @return {?proto.privilege_data_endpoint.GetPrivilegeDataReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.getSuccess = function() {
  return /** @type{?proto.privilege_data_endpoint.GetPrivilegeDataReplay} */ (
    jspb.Message.getWrapperField(this, proto.privilege_data_endpoint.GetPrivilegeDataReplay, 1));
};


/**
 * @param {?proto.privilege_data_endpoint.GetPrivilegeDataReplay|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataResponse} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.privilege_data_endpoint.GetPrivilegeDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataResponse} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.ResultCase}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse;
  return proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay;
      reader.readMessage(value,proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPrivilegeDataByIdReplay success = 1;
 * @return {?proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.getSuccess = function() {
  return /** @type{?proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay} */ (
    jspb.Message.getWrapperField(this, proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay, 1));
};


/**
 * @param {?proto.privilege_data_endpoint.GetPrivilegeDataByIdReplay|undefined} value
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse} returns this
*/
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse} returns this
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.privilege_data_endpoint.GetPrivilegeDataByIdResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.privilege_data_endpoint);
