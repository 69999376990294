/**
 * @fileoverview gRPC-Web generated client stub for privilege_data_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as privilegedata_pb from './privilegedata_pb';


export class PrivilegeDataEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.AddPrivilegeDataRequest,
    privilegedata_pb.AddPrivilegeDataResponse,
    (request: privilegedata_pb.AddPrivilegeDataRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.AddPrivilegeDataResponse.deserializeBinary
  );

  add(
    request: privilegedata_pb.AddPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.AddPrivilegeDataResponse>;

  add(
    request: privilegedata_pb.AddPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.AddPrivilegeDataResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.AddPrivilegeDataResponse>;

  add(
    request: privilegedata_pb.AddPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.AddPrivilegeDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.UpdatePrivilegeDataRequest,
    privilegedata_pb.UpdatePrivilegeDataResponse,
    (request: privilegedata_pb.UpdatePrivilegeDataRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.UpdatePrivilegeDataResponse.deserializeBinary
  );

  update(
    request: privilegedata_pb.UpdatePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.UpdatePrivilegeDataResponse>;

  update(
    request: privilegedata_pb.UpdatePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.UpdatePrivilegeDataResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.UpdatePrivilegeDataResponse>;

  update(
    request: privilegedata_pb.UpdatePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.UpdatePrivilegeDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.DaletePrivilegeDataRequest,
    privilegedata_pb.DeletePrivilegeDataResponse,
    (request: privilegedata_pb.DaletePrivilegeDataRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.DeletePrivilegeDataResponse.deserializeBinary
  );

  delete(
    request: privilegedata_pb.DaletePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.DeletePrivilegeDataResponse>;

  delete(
    request: privilegedata_pb.DaletePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.DeletePrivilegeDataResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.DeletePrivilegeDataResponse>;

  delete(
    request: privilegedata_pb.DaletePrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.DeletePrivilegeDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetPrivilegeData = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.GetPrivilegeDataRequest,
    privilegedata_pb.GetPrivilegeDataResponse,
    (request: privilegedata_pb.GetPrivilegeDataRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.GetPrivilegeDataResponse.deserializeBinary
  );

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.GetPrivilegeDataResponse>;

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.GetPrivilegeDataResponse>;

  getPrivilegeData(
    request: privilegedata_pb.GetPrivilegeDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilegeData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeData',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilegeData);
  }

  methodDescriptorGetPrivilegeDataById = new grpcWeb.MethodDescriptor(
    '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
    grpcWeb.MethodType.UNARY,
    privilegedata_pb.GetPrivilegeDataByIdRequest,
    privilegedata_pb.GetPrivilegeDataByIdResponse,
    (request: privilegedata_pb.GetPrivilegeDataByIdRequest) => {
      return request.serializeBinary();
    },
    privilegedata_pb.GetPrivilegeDataByIdResponse.deserializeBinary
  );

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<privilegedata_pb.GetPrivilegeDataByIdResponse>;

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataByIdResponse) => void): grpcWeb.ClientReadableStream<privilegedata_pb.GetPrivilegeDataByIdResponse>;

  getPrivilegeDataById(
    request: privilegedata_pb.GetPrivilegeDataByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: privilegedata_pb.GetPrivilegeDataByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
        request,
        metadata || {},
        this.methodDescriptorGetPrivilegeDataById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/privilege_data_endpoint.PrivilegeDataEndpoint/GetPrivilegeDataById',
    request,
    metadata || {},
    this.methodDescriptorGetPrivilegeDataById);
  }

}

