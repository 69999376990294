import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../app/Helpers';
import { GetPrivilegeDataByIdRequest, GetPrivilegeDataByIdResponse } from '../../../../../../../repository/UserManagement/privilegedata_pb';
import { number } from 'yup';
import { PrivilegeDataEndpointClient } from '../../../../../../../repository/UserManagement/PrivilegedataServiceClientPb';


export interface PrivilegeDataDetailsPagePageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: PrivilegeDataDetailsPagePageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new PrivilegeDataEndpointClient(API_URL, null, null);

export const getPrivilegeDataInfo = createAsyncThunk<GetPrivilegeDataByIdResponse.AsObject, APIRequest<GetPrivilegeDataByIdRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/privilegeDataDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getPrivilegeDataById(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)


export const managersPrivilegeDataDetailsPageSlice = createSlice({
    name: 'pages/management/privilegeDetails/privilegeDataDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetPrivilegeData: (state) => {
            state.item = undefined;

        },
        updatePrivilegeData: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivilegeDataInfo.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let obj;
                try {
                    obj = JSON.parse(payload.success?.validation?.value ?? "");
                } catch {
                    obj = undefined
                }
                state.item = {
                    id: payload.success?.id,
                    name: payload.success?.name?.value ?? "",
                    dataType: payload.success?.datatype,
                    propertyName: payload.success?.propertyname?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.addedby?.firstname?.value ?? "") + " " + (payload.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload.success?.addedby?.id?.value,
                    description: payload.success?.description?.value ?? "",
                    isRequired: payload.success?.isrequired,
                    validation: obj 
                }


            }
        })
        builder.addCase(getPrivilegeDataInfo.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getPrivilegeDataInfo.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetPrivilegeData, updatePrivilegeData } = managersPrivilegeDataDetailsPageSlice.actions

export default managersPrivilegeDataDetailsPageSlice.reducer