import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../app/Themes"
import { SearchField } from "../../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { TableState } from "../../../../common/Table/TableSate"
import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { GetUserTypesRequest } from "../../../../../repository/UserManagement/usertype_pb"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { deleteGroup, dismissMessage, getGroup, reset, resetGroup, updateGroup } from "./GroupDetailsPageSlice"
import { DeleteRoleRequest } from "../../../../../repository/UserManagement/role_pb"
import { DeleteGroupRequest, GetGroupsRequest } from "../../../../../repository/UserManagement/group_pb"
import { abort, getgroups } from "process"
import { Popup } from "../../../../common/Popup/Popup"
import { GroupsForm } from "../../../../forms/Management/Groups/GroupsForm"
import { FormType } from "../../../../forms/FormProps"
import { GroupMembersPage } from "./GroupMembersPage/GroupMembersPage"
import { TextView } from "../../../../common/DataView/TextView"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetGroupsRequest;

let org: number = -1;

let getGroupsPromise: any;
let actionPromise: any;


const key = "management/groups/details"
export const GroupDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.managersGroupDetailsPage.isChangeStateLoading,
            message: state.managersGroupDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.managersGroupDetailsPage.isLoading,
            item: state.managersGroupDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1)
            return deleteActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetGroupsRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));
        req.setNumofresults(1)

        if (id) {
            const wrapper = new StringValue();
            wrapper.setValue(id);
            req.setSearch(wrapper)
            getGroupsPromise = dispatch(getGroup({ body: req, headers: getHeaders() }))
            getGroupsPromise.unwrap().then((e: any) => {
                if (hash == "#members") {
                    setSelectedKey("1");
                } else {
                    setSelectedKey("0");
                }
            });
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getGroupsPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetGroup())
                getGroupsPromise = dispatch(getGroup({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t('edit'),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                setCurrenctAction(101)

            }

        },
        {
            key: 'delete',
            text: t("delete"),
            title: t('delete'),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t('share'),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <Popup isOpen={(currentAction == 101)} title={t("editGroup")} onDismiss={() => { setCurrenctAction(0) }} >

                <GroupsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateGroup(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareGroup")} value={`https://${window.location.host}/management/groups/${state.item.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteGroupDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new DeleteGroupRequest();
                                            req.setId(state.item?.id)
                                            if ((org != -1)) {
                                            }
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteGroup({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                navigate(-1)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <Pivot selectedKey={String(selectedKey)} onLinkClick={(i) => {
                        if (i?.props.itemKey == "1")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#members")

                        if (i?.props.itemKey == "0")
                            window.history.replaceState(null, "", "#info")
                        //navigate("#info")

                        setSelectedKey(i?.props?.itemKey ?? "0")
                    }}>
                        <PivotItem headerText={t("info")} itemKey="0" >
                            <div ref={ref}>
                                <CommandBar
                                    items={_selectionItems}
                                />
                                <Stack tokens={{ childrenGap: 10, padding: "0px 36% 10px 3%" }}>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("id")} value={state.item?.id} />


                                        <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                        {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            size={PersonaSize.size40} />

                                            : undefined}

                                    </Stack>

                                    <TextView label={t("groupName")} value={state.item?.name} />
                                    <TextView label={t("groupDescription")} value={state.item?.description} />


                                </Stack>
                            </div>
                        </PivotItem>
                        <PivotItem headerText={t("members")} itemKey="1" >
                            <GroupMembersPage group={{ id: Number(id), name: state.item?.name }} />
                        </PivotItem>
                    </Pivot>


            }


        </Stack>



    );
}




