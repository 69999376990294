import { CommandBar, Text, Stack, PrimaryButton, DefaultButton, Link, Modal, IconButton, IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles, Label, TextField, Layer, mergeStyles, AnimationClassNames } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/Hooks";
import { Settings } from "../../common/Settings/Settings";
import { isVisible } from "../../common/Settings/SettingsSlice";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Popup } from "../../common/Popup/Popup";
import { LoginForm } from "../../forms/Login/LoginForm";
import { useEffect } from "react";
import { getPage } from "../../../app/Pages";
import { setTitle } from "../../common/NavHeader/NavHeaderSlice";
import { normalizeKey } from "../../../app/Helpers";
const theme = getTheme();


const key = "login"

export const Login: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch()
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  useEffect(() => {

    const page = getPage(key)
    dispatch(setTitle(t(normalizeKey(page?.title))));

  }, [])
  return (
    <Stack verticalFill  >

      <Settings />
      <Popup isOpen={isModalOpen} title={t("signin")} onDismiss={hideModal} >
        <LoginForm childrenGap={10} maxWidth={280} onCancel={hideModal} />
      </Popup>


      <Stack verticalFill horizontal >
        <Stack verticalFill styles={{ root: { width: "60%", backgroundImage: "url(\"/img/background.jpg\")" } }}>

        </Stack>
        <Stack verticalFill grow >
          <CommandBar

            items={[]}
            farItems={[{
              key: 'settings',
              text: t("settings"),
              ariaLabel: t("settings"),
              iconOnly: true,
              iconProps: { iconName: 'Settings' },
              onClick: (ev, i) => {
                ev?.currentTarget.blur();
                dispatch(isVisible(true))
              }
            }
            ]}
            ariaLabel={t("commandBarAria")}
          />
          <Stack verticalFill tokens={{ childrenGap: 15, padding: 20 }}>
            <Text variant={'mega'} nowrap block>{t("dimensions")}</Text>

            <Stack tokens={{ childrenGap: 15 }}  >
              <Stack tokens={{ childrenGap: 15, padding: "40px 0 0 0" }}>
                <PrimaryButton onClick={showModal} styles={{ root: { borderRadius: 12, width: "20%", minWidth: 150 } }} text={t("signin")}></PrimaryButton>
              </Stack>


            </Stack>
          </Stack>
        </Stack>
      </Stack>

    </Stack >
  );
}

