// source: log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.clients_log_endpoint.ActionResponse', null, global);
goog.exportSymbol('proto.clients_log_endpoint.ClientResponse', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogReplay', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogRequest', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogResponse', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogsReplay', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogsRequest', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogsResponse', null, global);
goog.exportSymbol('proto.clients_log_endpoint.GetLogsResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_log_endpoint.Log', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.GetLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogRequest.displayName = 'proto.clients_log_endpoint.GetLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.GetLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogsRequest.displayName = 'proto.clients_log_endpoint.GetLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.ClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.ClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.ClientResponse.displayName = 'proto.clients_log_endpoint.ClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.ActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.ActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.ActionResponse.displayName = 'proto.clients_log_endpoint.ActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.GetLogReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogReplay.displayName = 'proto.clients_log_endpoint.GetLogReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_log_endpoint.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.Log.displayName = 'proto.clients_log_endpoint.Log';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_log_endpoint.GetLogsReplay.repeatedFields_, null);
};
goog.inherits(proto.clients_log_endpoint.GetLogsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogsReplay.displayName = 'proto.clients_log_endpoint.GetLogsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_log_endpoint.GetLogResponse.oneofGroups_);
};
goog.inherits(proto.clients_log_endpoint.GetLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogResponse.displayName = 'proto.clients_log_endpoint.GetLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_log_endpoint.GetLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_log_endpoint.GetLogsResponse.oneofGroups_);
};
goog.inherits(proto.clients_log_endpoint.GetLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_log_endpoint.GetLogsResponse.displayName = 'proto.clients_log_endpoint.GetLogsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogRequest}
 */
proto.clients_log_endpoint.GetLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogRequest;
  return proto.clients_log_endpoint.GetLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogRequest}
 */
proto.clients_log_endpoint.GetLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.clients_log_endpoint.GetLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_log_endpoint.GetLogRequest} returns this
 */
proto.clients_log_endpoint.GetLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: jspb.Message.getFieldWithDefault(msg, 1, 0),
    executer: (f = msg.getExecuter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    action: (f = msg.getAction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    objectid: (f = msg.getObjectid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logtype: (f = msg.getLogtype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 9, 0),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogsRequest}
 */
proto.clients_log_endpoint.GetLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogsRequest;
  return proto.clients_log_endpoint.GetLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogsRequest}
 */
proto.clients_log_endpoint.GetLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExecuter(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setObjectid(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setLogtype(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExecuter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getObjectid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogtype();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 organization = 1;
 * @return {number}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getOrganization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue executer = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getExecuter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setExecuter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearExecuter = function() {
  return this.setExecuter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasExecuter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue action = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getAction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasAction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue objectId = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getObjectid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setObjectid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearObjectid = function() {
  return this.setObjectid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasObjectid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value logType = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getLogtype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setLogtype = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearLogtype = function() {
  return this.setLogtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasLogtype = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue search = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool order = 7;
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 numOfResults = 9;
 * @return {number}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp from = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp to = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
*/
proto.clients_log_endpoint.GetLogsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsRequest} returns this
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.ClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.ClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.ClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.ClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: (f = msg.getIp()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    useragent: (f = msg.getUseragent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.ClientResponse}
 */
proto.clients_log_endpoint.ClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.ClientResponse;
  return proto.clients_log_endpoint.ClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.ClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.ClientResponse}
 */
proto.clients_log_endpoint.ClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIp(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUseragent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.ClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.ClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.ClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.ClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUseragent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue IP = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ClientResponse.prototype.getIp = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ClientResponse} returns this
*/
proto.clients_log_endpoint.ClientResponse.prototype.setIp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ClientResponse} returns this
 */
proto.clients_log_endpoint.ClientResponse.prototype.clearIp = function() {
  return this.setIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ClientResponse.prototype.hasIp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue userAgent = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ClientResponse.prototype.getUseragent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ClientResponse} returns this
*/
proto.clients_log_endpoint.ClientResponse.prototype.setUseragent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ClientResponse} returns this
 */
proto.clients_log_endpoint.ClientResponse.prototype.clearUseragent = function() {
  return this.setUseragent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ClientResponse.prototype.hasUseragent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.ActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.ActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.ActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.ActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    pb_class: (f = msg.getClass()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    endpoint: (f = msg.getEndpoint()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.ActionResponse}
 */
proto.clients_log_endpoint.ActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.ActionResponse;
  return proto.clients_log_endpoint.ActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.ActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.ActionResponse}
 */
proto.clients_log_endpoint.ActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClass(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.ActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.ActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.ActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.ActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClass();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEndpoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ActionResponse.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
*/
proto.clients_log_endpoint.ActionResponse.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
 */
proto.clients_log_endpoint.ActionResponse.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ActionResponse.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ActionResponse.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
*/
proto.clients_log_endpoint.ActionResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
 */
proto.clients_log_endpoint.ActionResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ActionResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue class = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ActionResponse.prototype.getClass = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
*/
proto.clients_log_endpoint.ActionResponse.prototype.setClass = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
 */
proto.clients_log_endpoint.ActionResponse.prototype.clearClass = function() {
  return this.setClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ActionResponse.prototype.hasClass = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue endpoint = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.ActionResponse.prototype.getEndpoint = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
*/
proto.clients_log_endpoint.ActionResponse.prototype.setEndpoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.ActionResponse} returns this
 */
proto.clients_log_endpoint.ActionResponse.prototype.clearEndpoint = function() {
  return this.setEndpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.ActionResponse.prototype.hasEndpoint = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    executer: (f = msg.getExecuter()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    action: (f = msg.getAction()) && proto.clients_log_endpoint.ActionResponse.toObject(includeInstance, f),
    client: (f = msg.getClient()) && proto.clients_log_endpoint.ClientResponse.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    objectid: (f = msg.getObjectid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    inputs: (f = msg.getInputs()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    outputs: (f = msg.getOutputs()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    exception: (f = msg.getException()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    startexecutiondate: (f = msg.getStartexecutiondate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endexecutiondate: (f = msg.getEndexecutiondate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogReplay}
 */
proto.clients_log_endpoint.GetLogReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogReplay;
  return proto.clients_log_endpoint.GetLogReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogReplay}
 */
proto.clients_log_endpoint.GetLogReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setExecuter(value);
      break;
    case 4:
      var value = new proto.clients_log_endpoint.ActionResponse;
      reader.readMessage(value,proto.clients_log_endpoint.ActionResponse.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 5:
      var value = new proto.clients_log_endpoint.ClientResponse;
      reader.readMessage(value,proto.clients_log_endpoint.ClientResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setObjectid(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInputs(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutputs(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setException(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartexecutiondate(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndexecutiondate(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExecuter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.clients_log_endpoint.ActionResponse.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.clients_log_endpoint.ClientResponse.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getObjectid();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInputs();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOutputs();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getException();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartexecutiondate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndexecutiondate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value type = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse executer = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getExecuter = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setExecuter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearExecuter = function() {
  return this.setExecuter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasExecuter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ActionResponse action = 4;
 * @return {?proto.clients_log_endpoint.ActionResponse}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getAction = function() {
  return /** @type{?proto.clients_log_endpoint.ActionResponse} */ (
    jspb.Message.getWrapperField(this, proto.clients_log_endpoint.ActionResponse, 4));
};


/**
 * @param {?proto.clients_log_endpoint.ActionResponse|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasAction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClientResponse client = 5;
 * @return {?proto.clients_log_endpoint.ClientResponse}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getClient = function() {
  return /** @type{?proto.clients_log_endpoint.ClientResponse} */ (
    jspb.Message.getWrapperField(this, proto.clients_log_endpoint.ClientResponse, 5));
};


/**
 * @param {?proto.clients_log_endpoint.ClientResponse|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasClient = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue description = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue objectId = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getObjectid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setObjectid = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearObjectid = function() {
  return this.setObjectid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasObjectid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue inputs = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getInputs = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setInputs = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearInputs = function() {
  return this.setInputs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasInputs = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue outputs = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getOutputs = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setOutputs = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearOutputs = function() {
  return this.setOutputs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasOutputs = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue exception = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getException = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setException = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearException = function() {
  return this.setException(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasException = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp startExecutionDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getStartexecutiondate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setStartexecutiondate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearStartexecutiondate = function() {
  return this.setStartexecutiondate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasStartexecutiondate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp endExecutionDate = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getEndexecutiondate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setEndexecutiondate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearEndexecutiondate = function() {
  return this.setEndexecutiondate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasEndexecutiondate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
*/
proto.clients_log_endpoint.GetLogReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogReplay} returns this
 */
proto.clients_log_endpoint.GetLogReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    executer: (f = msg.getExecuter()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    action: (f = msg.getAction()) && proto.clients_log_endpoint.ActionResponse.toObject(includeInstance, f),
    objectid: (f = msg.getObjectid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.Log}
 */
proto.clients_log_endpoint.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.Log;
  return proto.clients_log_endpoint.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.Log}
 */
proto.clients_log_endpoint.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setExecuter(value);
      break;
    case 4:
      var value = new proto.clients_log_endpoint.ActionResponse;
      reader.readMessage(value,proto.clients_log_endpoint.ActionResponse.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setObjectid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExecuter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.clients_log_endpoint.ActionResponse.serializeBinaryToWriter
    );
  }
  f = message.getObjectid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.clients_log_endpoint.Log.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value type = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_log_endpoint.Log.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_log_endpoint.Log} returns this
*/
proto.clients_log_endpoint.Log.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.Log.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse executer = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_log_endpoint.Log.prototype.getExecuter = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_log_endpoint.Log} returns this
*/
proto.clients_log_endpoint.Log.prototype.setExecuter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.clearExecuter = function() {
  return this.setExecuter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.Log.prototype.hasExecuter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ActionResponse action = 4;
 * @return {?proto.clients_log_endpoint.ActionResponse}
 */
proto.clients_log_endpoint.Log.prototype.getAction = function() {
  return /** @type{?proto.clients_log_endpoint.ActionResponse} */ (
    jspb.Message.getWrapperField(this, proto.clients_log_endpoint.ActionResponse, 4));
};


/**
 * @param {?proto.clients_log_endpoint.ActionResponse|undefined} value
 * @return {!proto.clients_log_endpoint.Log} returns this
*/
proto.clients_log_endpoint.Log.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.Log.prototype.hasAction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue objectId = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_log_endpoint.Log.prototype.getObjectid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_log_endpoint.Log} returns this
*/
proto.clients_log_endpoint.Log.prototype.setObjectid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.clearObjectid = function() {
  return this.setObjectid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.Log.prototype.hasObjectid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_log_endpoint.Log.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_log_endpoint.Log} returns this
*/
proto.clients_log_endpoint.Log.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.Log} returns this
 */
proto.clients_log_endpoint.Log.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.Log.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_log_endpoint.GetLogsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.clients_log_endpoint.Log.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogsReplay}
 */
proto.clients_log_endpoint.GetLogsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogsReplay;
  return proto.clients_log_endpoint.GetLogsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogsReplay}
 */
proto.clients_log_endpoint.GetLogsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_log_endpoint.Log;
      reader.readMessage(value,proto.clients_log_endpoint.Log.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.clients_log_endpoint.Log.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Log logs = 1;
 * @return {!Array<!proto.clients_log_endpoint.Log>}
 */
proto.clients_log_endpoint.GetLogsReplay.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.clients_log_endpoint.Log>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.clients_log_endpoint.Log, 1));
};


/**
 * @param {!Array<!proto.clients_log_endpoint.Log>} value
 * @return {!proto.clients_log_endpoint.GetLogsReplay} returns this
*/
proto.clients_log_endpoint.GetLogsReplay.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.clients_log_endpoint.Log=} opt_value
 * @param {number=} opt_index
 * @return {!proto.clients_log_endpoint.Log}
 */
proto.clients_log_endpoint.GetLogsReplay.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.clients_log_endpoint.Log, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_log_endpoint.GetLogsReplay} returns this
 */
proto.clients_log_endpoint.GetLogsReplay.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_log_endpoint.GetLogResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_log_endpoint.GetLogResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_log_endpoint.GetLogResponse.ResultCase}
 */
proto.clients_log_endpoint.GetLogResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_log_endpoint.GetLogResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_log_endpoint.GetLogResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_log_endpoint.GetLogReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogResponse}
 */
proto.clients_log_endpoint.GetLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogResponse;
  return proto.clients_log_endpoint.GetLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogResponse}
 */
proto.clients_log_endpoint.GetLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_log_endpoint.GetLogReplay;
      reader.readMessage(value,proto.clients_log_endpoint.GetLogReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_log_endpoint.GetLogReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetLogReplay success = 1;
 * @return {?proto.clients_log_endpoint.GetLogReplay}
 */
proto.clients_log_endpoint.GetLogResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_log_endpoint.GetLogReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_log_endpoint.GetLogReplay, 1));
};


/**
 * @param {?proto.clients_log_endpoint.GetLogReplay|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogResponse} returns this
*/
proto.clients_log_endpoint.GetLogResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_log_endpoint.GetLogResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogResponse} returns this
 */
proto.clients_log_endpoint.GetLogResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_log_endpoint.GetLogsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_log_endpoint.GetLogsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_log_endpoint.GetLogsResponse.ResultCase}
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_log_endpoint.GetLogsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_log_endpoint.GetLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_log_endpoint.GetLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_log_endpoint.GetLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_log_endpoint.GetLogsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_log_endpoint.GetLogsResponse}
 */
proto.clients_log_endpoint.GetLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_log_endpoint.GetLogsResponse;
  return proto.clients_log_endpoint.GetLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_log_endpoint.GetLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_log_endpoint.GetLogsResponse}
 */
proto.clients_log_endpoint.GetLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_log_endpoint.GetLogsReplay;
      reader.readMessage(value,proto.clients_log_endpoint.GetLogsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_log_endpoint.GetLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_log_endpoint.GetLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_log_endpoint.GetLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_log_endpoint.GetLogsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetLogsReplay success = 1;
 * @return {?proto.clients_log_endpoint.GetLogsReplay}
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_log_endpoint.GetLogsReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_log_endpoint.GetLogsReplay, 1));
};


/**
 * @param {?proto.clients_log_endpoint.GetLogsReplay|undefined} value
 * @return {!proto.clients_log_endpoint.GetLogsResponse} returns this
*/
proto.clients_log_endpoint.GetLogsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_log_endpoint.GetLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_log_endpoint.GetLogsResponse} returns this
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_log_endpoint.GetLogsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.clients_log_endpoint);
