/**
 * @fileoverview gRPC-Web generated client stub for organization_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as organization_pb from './organization_pb';


export class OrganizationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddOrganizationRequest,
    organization_pb.AddOrganizationResponse,
    (request: organization_pb.AddOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddOrganizationResponse.deserializeBinary
  );

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddOrganizationResponse>;

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddOrganizationResponse>;

  add(
    request: organization_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    organization_pb.UpdateOrganizationRequest,
    organization_pb.UpdateOrganizationResponse,
    (request: organization_pb.UpdateOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UpdateOrganizationResponse.deserializeBinary
  );

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UpdateOrganizationResponse>;

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UpdateOrganizationResponse>;

  update(
    request: organization_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    organization_pb.UpdateOrganizationStateRequest,
    organization_pb.UpdateOrganizationActivityResponse,
    (request: organization_pb.UpdateOrganizationStateRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UpdateOrganizationActivityResponse.deserializeBinary
  );

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UpdateOrganizationActivityResponse>;

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationActivityResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UpdateOrganizationActivityResponse>;

  updateActivity(
    request: organization_pb.UpdateOrganizationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UpdateOrganizationActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorGetOrganizations = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetOrganizationsRequest,
    organization_pb.GetOrganizationsResponse,
    (request: organization_pb.GetOrganizationsRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetOrganizationsResponse.deserializeBinary
  );

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetOrganizationsResponse>;

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationsResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetOrganizationsResponse>;

  getOrganizations(
    request: organization_pb.GetOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
        request,
        metadata || {},
        this.methodDescriptorGetOrganizations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetOrganizations',
    request,
    metadata || {},
    this.methodDescriptorGetOrganizations);
  }

  methodDescriptorGetOrganization = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetOrganization',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetOrganizationRequest,
    organization_pb.GetOrganizationResponse,
    (request: organization_pb.GetOrganizationRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetOrganizationResponse.deserializeBinary
  );

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetOrganizationResponse>;

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetOrganizationResponse>;

  getOrganization(
    request: organization_pb.GetOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetOrganization',
        request,
        metadata || {},
        this.methodDescriptorGetOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetOrganization',
    request,
    metadata || {},
    this.methodDescriptorGetOrganization);
  }

  methodDescriptorAddRole = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/AddRole',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddRoleRequest,
    organization_pb.AddRoleResponse,
    (request: organization_pb.AddRoleRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddRoleResponse.deserializeBinary
  );

  addRole(
    request: organization_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddRoleResponse>;

  addRole(
    request: organization_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddRoleResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddRoleResponse>;

  addRole(
    request: organization_pb.AddRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/AddRole',
        request,
        metadata || {},
        this.methodDescriptorAddRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/AddRole',
    request,
    metadata || {},
    this.methodDescriptorAddRole);
  }

  methodDescriptorGetRoles = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetRoles',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetRolesRequest,
    organization_pb.GetRolesResponse,
    (request: organization_pb.GetRolesRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetRolesResponse.deserializeBinary
  );

  getRoles(
    request: organization_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetRolesResponse>;

  getRoles(
    request: organization_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetRolesResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetRolesResponse>;

  getRoles(
    request: organization_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetRoles',
        request,
        metadata || {},
        this.methodDescriptorGetRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetRoles',
    request,
    metadata || {},
    this.methodDescriptorGetRoles);
  }

  methodDescriptorAddUserType = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/AddUserType',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddUserTypeRequest,
    organization_pb.AddUserTypeResponse,
    (request: organization_pb.AddUserTypeRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddUserTypeResponse.deserializeBinary
  );

  addUserType(
    request: organization_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddUserTypeResponse>;

  addUserType(
    request: organization_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddUserTypeResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddUserTypeResponse>;

  addUserType(
    request: organization_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddUserTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/AddUserType',
        request,
        metadata || {},
        this.methodDescriptorAddUserType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/AddUserType',
    request,
    metadata || {},
    this.methodDescriptorAddUserType);
  }

  methodDescriptorGetUserTypes = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetUserTypes',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetUserTypesRequest,
    organization_pb.GetUserTypesResponse,
    (request: organization_pb.GetUserTypesRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetUserTypesResponse.deserializeBinary
  );

  getUserTypes(
    request: organization_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetUserTypesResponse>;

  getUserTypes(
    request: organization_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetUserTypesResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetUserTypesResponse>;

  getUserTypes(
    request: organization_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetUserTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetUserTypes',
        request,
        metadata || {},
        this.methodDescriptorGetUserTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetUserTypes',
    request,
    metadata || {},
    this.methodDescriptorGetUserTypes);
  }

  methodDescriptorAddGroup = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/AddGroup',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddGroupRequest,
    organization_pb.AddGroupResponse,
    (request: organization_pb.AddGroupRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddGroupResponse.deserializeBinary
  );

  addGroup(
    request: organization_pb.AddGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddGroupResponse>;

  addGroup(
    request: organization_pb.AddGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddGroupResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddGroupResponse>;

  addGroup(
    request: organization_pb.AddGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/AddGroup',
        request,
        metadata || {},
        this.methodDescriptorAddGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/AddGroup',
    request,
    metadata || {},
    this.methodDescriptorAddGroup);
  }

  methodDescriptorGetGroups = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetGroups',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetGroupsRequest,
    organization_pb.GetGroupsResponse,
    (request: organization_pb.GetGroupsRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetGroupsResponse.deserializeBinary
  );

  getGroups(
    request: organization_pb.GetGroupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetGroupsResponse>;

  getGroups(
    request: organization_pb.GetGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetGroupsResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetGroupsResponse>;

  getGroups(
    request: organization_pb.GetGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetGroups',
        request,
        metadata || {},
        this.methodDescriptorGetGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetGroups',
    request,
    metadata || {},
    this.methodDescriptorGetGroups);
  }

  methodDescriptorAddPrivileges = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/AddPrivileges',
    grpcWeb.MethodType.UNARY,
    organization_pb.AddPrivilegesRequest,
    organization_pb.AddPrivilegesResponse,
    (request: organization_pb.AddPrivilegesRequest) => {
      return request.serializeBinary();
    },
    organization_pb.AddPrivilegesResponse.deserializeBinary
  );

  addPrivileges(
    request: organization_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.AddPrivilegesResponse>;

  addPrivileges(
    request: organization_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.AddPrivilegesResponse) => void): grpcWeb.ClientReadableStream<organization_pb.AddPrivilegesResponse>;

  addPrivileges(
    request: organization_pb.AddPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.AddPrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/AddPrivileges',
        request,
        metadata || {},
        this.methodDescriptorAddPrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/AddPrivileges',
    request,
    metadata || {},
    this.methodDescriptorAddPrivileges);
  }

  methodDescriptorGetPrivileges = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/GetPrivileges',
    grpcWeb.MethodType.UNARY,
    organization_pb.GetPrivilegesRequest,
    organization_pb.GetPrivilegesResponse,
    (request: organization_pb.GetPrivilegesRequest) => {
      return request.serializeBinary();
    },
    organization_pb.GetPrivilegesResponse.deserializeBinary
  );

  getPrivileges(
    request: organization_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: organization_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.GetPrivilegesResponse) => void): grpcWeb.ClientReadableStream<organization_pb.GetPrivilegesResponse>;

  getPrivileges(
    request: organization_pb.GetPrivilegesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.GetPrivilegesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/GetPrivileges',
        request,
        metadata || {},
        this.methodDescriptorGetPrivileges,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/GetPrivileges',
    request,
    metadata || {},
    this.methodDescriptorGetPrivileges);
  }

  methodDescriptorRemovePrivilege = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/RemovePrivilege',
    grpcWeb.MethodType.UNARY,
    organization_pb.RemovePrivilegeRequest,
    organization_pb.RemovePrivilegeResponse,
    (request: organization_pb.RemovePrivilegeRequest) => {
      return request.serializeBinary();
    },
    organization_pb.RemovePrivilegeResponse.deserializeBinary
  );

  removePrivilege(
    request: organization_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.RemovePrivilegeResponse>;

  removePrivilege(
    request: organization_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.RemovePrivilegeResponse) => void): grpcWeb.ClientReadableStream<organization_pb.RemovePrivilegeResponse>;

  removePrivilege(
    request: organization_pb.RemovePrivilegeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.RemovePrivilegeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/RemovePrivilege',
        request,
        metadata || {},
        this.methodDescriptorRemovePrivilege,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/RemovePrivilege',
    request,
    metadata || {},
    this.methodDescriptorRemovePrivilege);
  }

  methodDescriptorUploadLogo = new grpcWeb.MethodDescriptor(
    '/organization_endpoint.OrganizationEndpoint/UploadLogo',
    grpcWeb.MethodType.UNARY,
    organization_pb.UploadLogoRequest,
    organization_pb.UploadLogoResponse,
    (request: organization_pb.UploadLogoRequest) => {
      return request.serializeBinary();
    },
    organization_pb.UploadLogoResponse.deserializeBinary
  );

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null): Promise<organization_pb.UploadLogoResponse>;

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organization_pb.UploadLogoResponse) => void): grpcWeb.ClientReadableStream<organization_pb.UploadLogoResponse>;

  uploadLogo(
    request: organization_pb.UploadLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organization_pb.UploadLogoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_endpoint.OrganizationEndpoint/UploadLogo',
        request,
        metadata || {},
        this.methodDescriptorUploadLogo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_endpoint.OrganizationEndpoint/UploadLogo',
    request,
    metadata || {},
    this.methodDescriptorUploadLogo);
  }

}

