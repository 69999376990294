import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../repository/UserManagement/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../repository/UserManagement/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession } from '../../../../app/Api';

export interface RolesFormState {
  isLoading: boolean
  message?: ApiMessage
}

const initialState: RolesFormState = {
  isLoading: false,
  message: undefined
}

const client = new RoleEndpointClient(API_URL, null, null);
export const add = createAsyncThunk<AddRoleResponse.AsObject, APIRequest<AddRoleRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/management/roles/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.add(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const update = createAsyncThunk<UpdateRoleResponse.AsObject, APIRequest<UpdateRoleRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/management/roles/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const rolesFormSlice = createSlice({
  name: 'forms/management/roles',
  initialState,
  reducers: {

    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
  }
})

export const { reset, dismissMessage } = rolesFormSlice.actions

export default rolesFormSlice.reducer


