import { ThemeProvider } from "@fluentui/react"
import { BrowserRouter, Navigate, NavigateFunction, NavigateOptions, Route, Routes, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./app/Hooks"
import { getPage } from "./app/Pages"
import { AppTheme, getMainTheme, ThemeManager } from "./app/Themes"
import { MasterLayout } from "./components/layouts/Master/MasterLayout"
import { Login } from "./components/layouts/Login/LoginLayout"
import { useContext, useEffect } from "react"
import { clearSession, isLoggedIn } from "./app/Api"
import { UsersPage } from "./components/pages/Management/Users/UsersPage"
import { RolesPage } from "./components/pages/Management/Roles/RolesPage"
import { UserTypesPage } from "./components/pages/Management/UserTypes/UserTypesPage"
import { PrivilegesPage } from "./components/pages/Management/Privileges/PrivilegesPage"
import { GroupsPage } from "./components/pages/Management/Groups/GroupsPage"
import { OrganizationsPage } from "./components/pages/Organizations/OrganizationsPage"
import { UsersEntryPage } from "./components/pages/Management/Users/UsersEntryPage/UsersEntryPage"
import { UserTypeDetailsPage } from "./components/pages/Management/UserTypes/UserTypeDetailsPage/UserTypeDetailsPage"
import { UserDetailsPage } from "./components/pages/Management/Users/UserDetailsPage/UserDetailsPage"
import { RoleDetailsPage } from "./components/pages/Management/Roles/RoleDetailsPage/RoleDetailsPage"
import { GroupDetailsPage } from "./components/pages/Management/Groups/GroupDetailsPage/GroupDetailsPage"
import { PrivilegeDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDetailsPage"
import { OrganizationDetailsPage } from "./components/pages/Organizations/OrganizationDetailsPage/OrganizationDetailsPage"
import { PrivilegeDataDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPage"
import { ProfilePage } from "./components/pages/Profile/ProfilePage"

import { UsersPage as CustomerUsersPage } from "./components/pages/Customers/Users/UsersPage"
import { RolesPage as CustomerRolesPage } from "./components/pages/Customers/Roles/RolesPage"
import { UserTypesPage as CustomerUserTypesPage } from "./components/pages/Customers/UserTypes/UserTypesPage"
import { PrivilegesPage as CustomerPrivilegesPage } from "./components/pages/Customers/Privileges/PrivilegesPage"
import { GroupsPage as CustomerGroupsPage } from "./components/pages/Customers/Groups/GroupsPage"
import { UserTypeDetailsPage as CustomerUserTypeDetailsPage } from "./components/pages/Customers/UserTypes/UserTypeDetailsPage/UserTypeDetailsPage"
import { UserDetailsPage as CustomerUserDetailsPage } from "./components/pages/Customers/Users/UserDetailsPage/UserDetailsPage"
import { RoleDetailsPage as CustomerRoleDetailsPage } from "./components/pages/Customers/Roles/RoleDetailsPage/RoleDetailsPage"
import { GroupDetailsPage as CustomerGroupDetailsPage } from "./components/pages/Customers/Groups/GroupDetailsPage/GroupDetailsPage"
import { PrivilegeDetailsPage as CustomerPrivilegeDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDetailsPage"
import { PrivilegeDataDetailsPage as CustomerPrivilegeDataDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPage"
import { RolePrivilegeDetailsPage as  CustomerRolePrivilegeDetailsPage } from "./components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPage"
import { DataRestrictionDetailsPage as CustomerDataRestrictionDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPage"
import { GroupMemberDetailsPage as CustomerGroupMemberDetailsPage} from "./components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPage"


import { UsersPage as ClientUsersPage } from "./components/pages/Customers/Clients/Users/UsersPage"
import { UserDetailsPage as ClientUserDetailsPage } from "./components/pages/Customers/Clients/Users/UserDetailsPage/UserDetailsPage"
import { ClientLogsPage } from "./components/pages/Customers/Clients/Logs/LogsPage"
import { ClientLogDetailsPage } from "./components/pages/Customers/Clients/Logs/LogDetailsPage/LogDetailsPage"
import { ClientPrivilegesPage } from "./components/pages/Customers/Clients/Privileges/PrivilegesPage"

import {  LogsPage } from "./components/pages/Logs/LogsPage"
import { LogDetailsPage } from "./components/pages/Logs/LogDetailsPage/LogDetailsPage"
import GroupMemberDetailsPageSlice from "./components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice"
import { GroupMemberDetailsPage } from "./components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPage"
import { RolePrivilegeDetailsPage } from "./components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPage"
import { DataRestrictionDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPage"
import { NotFound } from "./components/common/NotFound/NotFound"
import { DashboardPage } from "./components/pages/Dashboard/Dashboard"


export const App: React.FunctionComponent = () => {
    const state: { appTheme: string, isDarkModeEnabled: boolean, currentLang: string } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, currentLang: state.settings.currentLang }
    })
    return (
        <ThemeProvider theme={getMainTheme(state.appTheme, state.isDarkModeEnabled)} style={{ height: '100%', width: '100%' }}  >
            <BrowserRouter>
                <Routes>
         
                    <Route path="/" element={<RequireAuth redirectTo="/login"> <MasterLayout />  </RequireAuth>}>
                        <Route path='*' element={<NotFound />} />
                        <Route path={getPage("dashboard").path} element={<DashboardPage />} />
                        <Route path={getPage("profile").path} element={<ProfilePage />} />
                        <Route path={getPage("organizations").path} element={<OrganizationsPage />} />
                        <Route path={getPage("organizations/details").path} element={<OrganizationDetailsPage />} />
                        <Route path={getPage("management/users").path} element={<UsersPage />} />
                        <Route path={getPage("management/roles").path} element={<RolesPage />} />
                        <Route path={getPage("management/usersTypes").path} element={<UserTypesPage />} />
                        <Route path={getPage("management/privileges").path} element={<PrivilegesPage />} />
                        <Route path={getPage("management/groups").path} element={<GroupsPage />} />
                        <Route path={getPage("management/usersTypes/details").path} element={<UserTypeDetailsPage />} />
                        <Route path={getPage("management/users/details").path} element={<UserDetailsPage />} />
                        <Route path={getPage("management/roles/details").path} element={<RoleDetailsPage />} />
                        <Route path={getPage("management/groups/details").path} element={<GroupDetailsPage />} />
                        <Route path={getPage("management/privileges/details").path} element={<PrivilegeDetailsPage />} />
                        <Route path={getPage("management/privilege-data/details").path} element={<PrivilegeDataDetailsPage />} />
                        <Route path={getPage("management/groupMembership/details").path} element={<GroupMemberDetailsPage />} />
                        <Route path={getPage("management/rolePrivilege/details").path} element={<RolePrivilegeDetailsPage />} />
                        <Route path={getPage("management/dataRestrection/details").path} element={<DataRestrictionDetailsPage />} />

                        <Route path={getPage("customers/users").path} element={<CustomerUsersPage />} />
                        <Route path={getPage("customers/roles").path} element={<CustomerRolesPage />} />
                        <Route path={getPage("customers/usersTypes").path} element={<CustomerUserTypesPage />} />
                        <Route path={getPage("customers/privileges").path} element={<CustomerPrivilegesPage />} />
                        <Route path={getPage("customers/groups").path} element={<CustomerGroupsPage />} />
                        <Route path={getPage("customers/usersTypes/details").path} element={<CustomerUserTypeDetailsPage />} />
                        <Route path={getPage("customers/users/details").path} element={<CustomerUserDetailsPage />} />
                        <Route path={getPage("customers/roles/details").path} element={<CustomerRoleDetailsPage />} />
                        <Route path={getPage("customers/groups/details").path} element={<CustomerGroupDetailsPage />} />
                        <Route path={getPage("customers/privileges/details").path} element={<CustomerPrivilegeDetailsPage />} />
                        <Route path={getPage("customers/privilege-data/details").path} element={<CustomerPrivilegeDataDetailsPage />} />
                        <Route path={getPage("customers/groupMembership/details").path} element={<CustomerGroupMemberDetailsPage />} />
                        <Route path={getPage("customers/rolePrivilege/details").path} element={<CustomerRolePrivilegeDetailsPage />} />
                        <Route path={getPage("customers/dataRestrection/details").path} element={<CustomerDataRestrictionDetailsPage />} />

                        <Route path={getPage("clients/users").path} element={<ClientUsersPage />} />
                        <Route path={getPage("clients/users/details").path} element={<ClientUserDetailsPage />} />
                        <Route path={getPage("clients/privileges").path} element={<ClientPrivilegesPage />} />
                        <Route path={getPage("clients/logs").path} element={<ClientLogsPage />} />
                        <Route path={getPage("clients/logs/details").path} element={<ClientLogDetailsPage />} />



                        <Route path={getPage("logs").path} element={<LogsPage />} />
                        <Route path={getPage("logs/details").path} element={<LogDetailsPage />} />



                    </Route>

                    <Route path="/login" element={<BypassAuth><Login /></BypassAuth>} />
                </Routes>
            </BrowserRouter>

        </ThemeProvider >

    );
}


const RequireAuth: React.FunctionComponent<{ redirectTo: string, children: React.ReactNode }> = (props) => {
    let isAuthenticated = isLoggedIn();
    return (isAuthenticated ? props?.children as React.ReactElement : <Navigate to={props.redirectTo} />);
}

const BypassAuth: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
    let isAuthenticated = isLoggedIn();
    return (isAuthenticated ? <Navigate to="/" /> : props?.children as React.ReactElement);
}
