import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { number } from 'yup';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/RoleServiceClientPb';
import { RolePrivilegeEndpointClient } from '../../../../../repository/UserManagement/RoleprivilegeServiceClientPb';
import { GetAvailablePrivilegesResponse, GetAvailablePrivilegesRequest } from '../../../../../repository/UserManagement/role_pb';
import { AddBatchRolePrivilegeRequest, AddBatchRolePrivilegeResponse, AddRolePrivilegeRequest, AddRolePrivilegeResponse } from '../../../../../repository/UserManagement/roleprivilege_pb';


export interface RolePrivilegesFormState {
    privileges: TableState
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    isLoading: boolean,

}

const initialState: RolePrivilegesFormState = {
    privileges: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 500, selected: [], isFilteredSet: false },
    message: undefined,
    searchText: undefined,
    filters: [],
    isLoading: false,

}

const client = new RoleEndpointClient(API_URL, null, null);
const clientRolePrivilege = new RolePrivilegeEndpointClient(API_URL, null, null);


export const getPrivileges = createAsyncThunk<GetAvailablePrivilegesResponse.AsObject, APIRequest<GetAvailablePrivilegesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/management/roles/privileges/getAvailable',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getAvailablePrivileges(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)


export const add = createAsyncThunk<AddBatchRolePrivilegeResponse.AsObject, APIRequest<AddBatchRolePrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/management/roles/privileges/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await clientRolePrivilege.addBatch(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)
const getFilters = (state: RolePrivilegesFormState): any[] => {

    return [];

}
export const managersRolePrivilegesFormSlice = createSlice({
    name: 'forms/management/roles/privileges',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.privileges = initialState.privileges;
            state.searchText = initialState.searchText;
        },
        resetPrivileges: (state) => {
            state.privileges.items = [];
            state.privileges.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.privileges.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.privileges.numberOfResults = action.payload;
        },
        setSelectedPrivilege: (state, action: PayloadAction<any[]>) => {
            state.privileges.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.privileges.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivileges.fulfilled, (state, { payload }) => {
            state.privileges.isFetching = false
            if (payload) {
                var r = (payload?.success?.privilegesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        keyName: val.keyname?.value ?? "",
                        isAvailable: val.isavailable,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.privileges.items.length != 0) {
                    l = state.privileges.items.slice(0, state.privileges.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.privileges.numberOfResults) {
                    state.privileges.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.privileges.items = l
                //console.log(state.items)

            } else {
                state.privileges.hasMore = false;
            }
        })
        builder.addCase(getPrivileges.rejected, (state, action) => {
            state.privileges.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getPrivileges.pending, (state, action) => {
            state.privileges.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, resetPrivileges, setSelectedPrivilege, setSearchText, setIsFilteredSet } = managersRolePrivilegesFormSlice.actions

export default managersRolePrivilegesFormSlice.reducer


