import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession } from '../../../../app/Api';
import { UserTypeEndpointClient } from '../../../../repository/UserManagement/UsertypeServiceClientPb';
import { UpdateUserTypeRequest, UpdateUserTypeResponse } from '../../../../repository/UserManagement/usertype_pb';
import { AddUserTypeRequest, AddUserTypeResponse } from '../../../../repository/UserManagement/organization_pb';
import { OrganizationEndpointClient } from '../../../../repository/UserManagement/OrganizationServiceClientPb';


export interface UserTypesFormState {
  isLoading: boolean
  message?: ApiMessage
}

const initialState: UserTypesFormState = {
  isLoading: false,
  message: undefined
}

const client = new UserTypeEndpointClient(API_URL, null, null);
const clientOrg = new OrganizationEndpointClient(API_URL, null, null);

export const add = createAsyncThunk<AddUserTypeResponse.AsObject, APIRequest<AddUserTypeRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/userTypes/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await clientOrg.addUserType(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)


export const update = createAsyncThunk<UpdateUserTypeResponse.AsObject, APIRequest<UpdateUserTypeRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/customers/userTypes/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const userTypesFormSlice = createSlice({
  name: 'forms/customers/userTypes',
  initialState,
  reducers: {

    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
  }
})

export const { reset, dismissMessage } = userTypesFormSlice.actions

export default userTypesFormSlice.reducer


