import { IconButton, ISpinButtonProps, ITextFieldProps, Modal, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, Text, TextField } from "@fluentui/react";
import { Field, FieldProps } from "formik";

export const InputField: React.FunctionComponent<FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  return (
    <TextField   {...field} {...props} errorMessage={(touched[field.name] && errors[field.name]) ? errors[field.name]?.toString() : ""} />
  );

};

export const NumberInputField: React.FunctionComponent<FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  return (
    <SpinButton {...field} {...props} onChange={(e, o) => { setFieldValue(field.name, o) }}

    />
  );

};


export interface IDynamicInputField {
  name: string
}

export const DynamicInputField: React.FunctionComponent<ITextFieldProps & IDynamicInputField> = (props) => {

  return (
    <Field name={props.name} >
      {({ field, form, meta }: any) => (
        <TextField   {...props}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          errorMessage={meta.error ? meta.error : ""} />
      )
      }
    </Field>


  )

};


export const DynamicNumberInputField: React.FunctionComponent<ISpinButtonProps & IDynamicInputField> = (props) => {

  return (
    <Field name={props.name} >
      {({ field, form, meta }: any) => (
        <SpinButton   {...props}
          value={field.value}
          onChange={(e, o) => { form.setFieldValue(props.name, o) }}
          onBlur={field.onBlur}
        />
      )
      }
    </Field>


  )

};