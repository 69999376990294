import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { RemovePrivilegeRequest, RemovePrivilegeResponse } from '../../../../../repository/UserManagement/organization_pb';
import { GetPrivilegeRequest, GetPrivilegeResponse} from '../../../../../repository/UserManagement/privilege_pb';
import { number } from 'yup';
import { OrganizationEndpointClient } from '../../../../../repository/UserManagement/OrganizationServiceClientPb';
import { PrivilegeEndpointClient } from '../../../../../repository/UserManagement/PrivilegeServiceClientPb';


export interface PrivilegeDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: PrivilegeDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new PrivilegeEndpointClient(API_URL, null, null);
const clientOrg = new OrganizationEndpointClient(API_URL, null, null);

export const getPrivilege = createAsyncThunk<GetPrivilegeResponse.AsObject, APIRequest<GetPrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/customers/privilegeDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getPrivilege(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)


export const removePrivilege = createAsyncThunk<APIResponse<RemovePrivilegeResponse.AsObject>, APIRequest<RemovePrivilegeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/customers/privilegeDetails/remove',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await clientOrg.removePrivilege(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getPrivilegeid() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const customersPrivilegeDetailsPageSlice = createSlice({
    name: 'pages/customers/privilegeDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetPrivilege: (state) => {
            state.item = undefined;

        },

        updatePrivilege: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivilege.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                state.item = {
                    id: payload.success?.id ,
                    name: payload.success?.name?.value ?? "",
                    keyName: payload.success?.keyname?.value ?? "",
                    isAvailable: payload.success?.isavailable,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.addedby?.firstname?.value ?? "") + " " + (payload.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload.success?.addedby?.id?.value,
                    className: payload.success?.classname?.value ?? "",
                    methodName: payload.success?.methodname?.value ?? "",
                }
            }
        })
        builder.addCase(getPrivilege.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getPrivilege.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(removePrivilege.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(removePrivilege.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removePrivilege.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetPrivilege, updatePrivilege } = customersPrivilegeDetailsPageSlice.actions

export default customersPrivilegeDetailsPageSlice.reducer