import { DefaultButton, DialogFooter, IconButton, IStackTokens, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, reset, update } from "./UserTypesFormSlice";
import * as Yup from 'yup';
import { InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { UpdateUserTypeRequest, UpdateUserTypeResponse } from "../../../../repository/UserManagement/usertype_pb";
import { AddUserTypeRequest, AddUserTypeResponse } from "../../../../repository/UserManagement/organization_pb";


let req: AddUserTypeRequest;
let updateReq: UpdateUserTypeRequest;

let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const UserTypesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined } = useAppSelector((state) => {

        return { isLoading: state.customersUserTypesForm.isLoading, message: state.customersUserTypesForm.message }
    })

    useEffect(() => {
        req = new AddUserTypeRequest();
        updateReq = new UpdateUserTypeRequest()
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setId(org)
                //updateReq.setOrganization(wrapper)
            }
        }


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    const iconWithTooltip = (
        <>
            <TooltipHost content={showTooltip ? 'Info tooltip' : undefined} id={tooltipId}>
                <DefaultButton
                    aria-label={'more info'}
                    aria-describedby={showTooltip ? tooltipId : undefined}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setShowTooltip(!showTooltip)}
                    styles={{
                        root: {
                            background: 'transparent',
                            border: 'none',
                            minWidth: '16px',
                            padding: 0,
                        },
                    }}
                    iconProps={{ iconName: 'Info' }}
                />
            </TooltipHost>
        </>
    );
    return (
        <>

            <Formik


                initialValues={{

                    name: (props.renderObject) ? props.renderObject.name : '',

                }}

                validationSchema={Yup.object({

                    name: inputs.userTypeName,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        req.setName(values.name.trim());
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddUserTypeResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.usertypeid?.value,
                                            name: values.name.trim(),
                                            userTypeCategory: 1,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setName(values.name.trim());
                        updateReq.setId(props.renderObject?.id);
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateUserTypeResponse.AsObject) => {

                                if (res) {

                                    if (props?.onSuccess) {
                                        props?.onSuccess({ ...props.renderObject, name: values.name.trim() });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Field name="name" label={t("userTypeName")} placeholder={t("userTypeName")} component={InputField} disabled={state.isLoading} required autoFocus maxLength={50} />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </>
    );

}

