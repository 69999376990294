import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, getSession, refreshSession } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { DeleteUserTypeRequest, DeleteUserTypeResponse } from '../../../../../repository/UserManagement/usertype_pb';
import { UserTypeEndpointClient } from '../../../../../repository/UserManagement/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../../../repository/UserManagement/organization_pb';
import { number } from 'yup';
import { OrganizationEndpointClient } from '../../../../../repository/UserManagement/OrganizationServiceClientPb';


export interface UserTypeDetailsPageState {
    userType: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean
}

const initialState: UserTypeDetailsPageState = {
    userType: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const clientUserTypes = new UserTypeEndpointClient(API_URL, null, null);
const clientOrg = new OrganizationEndpointClient(API_URL, null, null);


export const getUserType = createAsyncThunk<GetUserTypesResponse.AsObject, APIRequest<GetUserTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/customers/userTypeDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientOrg.getUserTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const deleteUserType = createAsyncThunk<APIResponse<DeleteUserTypeResponse.AsObject>, APIRequest<DeleteUserTypeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/customers/userTypeDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientUserTypes.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }

)


export const customersUserTypeDetailsPageSlice = createSlice({
    name: 'pages/customers/userTypeDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetUserType: (state) => {
            state.userType = undefined;

        },

        updateUserType: (state, action: PayloadAction<any>) => {
            state.userType = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserType.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload?.success?.usertypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        userTypeCategory: val.usertypecategory,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])

                state.userType = r.at(0);
            }
        })
        builder.addCase(getUserType.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;

            }
        })
        builder.addCase(getUserType.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(deleteUserType.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteUserType.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteUserType.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, updateUserType, resetUserType } = customersUserTypeDetailsPageSlice.actions

export default customersUserTypeDetailsPageSlice.reducer


