/**
 * @fileoverview gRPC-Web generated client stub for user_type_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as usertype_pb from './usertype_pb';


export class UserTypeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/user_type_endpoint.UserTypeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    usertype_pb.AddUserTypeRequest,
    usertype_pb.AddUserTypeResponse,
    (request: usertype_pb.AddUserTypeRequest) => {
      return request.serializeBinary();
    },
    usertype_pb.AddUserTypeResponse.deserializeBinary
  );

  add(
    request: usertype_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<usertype_pb.AddUserTypeResponse>;

  add(
    request: usertype_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: usertype_pb.AddUserTypeResponse) => void): grpcWeb.ClientReadableStream<usertype_pb.AddUserTypeResponse>;

  add(
    request: usertype_pb.AddUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: usertype_pb.AddUserTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user_type_endpoint.UserTypeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user_type_endpoint.UserTypeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/user_type_endpoint.UserTypeEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    usertype_pb.UpdateUserTypeRequest,
    usertype_pb.UpdateUserTypeResponse,
    (request: usertype_pb.UpdateUserTypeRequest) => {
      return request.serializeBinary();
    },
    usertype_pb.UpdateUserTypeResponse.deserializeBinary
  );

  update(
    request: usertype_pb.UpdateUserTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<usertype_pb.UpdateUserTypeResponse>;

  update(
    request: usertype_pb.UpdateUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: usertype_pb.UpdateUserTypeResponse) => void): grpcWeb.ClientReadableStream<usertype_pb.UpdateUserTypeResponse>;

  update(
    request: usertype_pb.UpdateUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: usertype_pb.UpdateUserTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user_type_endpoint.UserTypeEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user_type_endpoint.UserTypeEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/user_type_endpoint.UserTypeEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    usertype_pb.DeleteUserTypeRequest,
    usertype_pb.DeleteUserTypeResponse,
    (request: usertype_pb.DeleteUserTypeRequest) => {
      return request.serializeBinary();
    },
    usertype_pb.DeleteUserTypeResponse.deserializeBinary
  );

  delete(
    request: usertype_pb.DeleteUserTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<usertype_pb.DeleteUserTypeResponse>;

  delete(
    request: usertype_pb.DeleteUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: usertype_pb.DeleteUserTypeResponse) => void): grpcWeb.ClientReadableStream<usertype_pb.DeleteUserTypeResponse>;

  delete(
    request: usertype_pb.DeleteUserTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: usertype_pb.DeleteUserTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user_type_endpoint.UserTypeEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user_type_endpoint.UserTypeEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetUserTypes = new grpcWeb.MethodDescriptor(
    '/user_type_endpoint.UserTypeEndpoint/GetUserTypes',
    grpcWeb.MethodType.UNARY,
    usertype_pb.GetUserTypesRequest,
    usertype_pb.GetUserTypesResponse,
    (request: usertype_pb.GetUserTypesRequest) => {
      return request.serializeBinary();
    },
    usertype_pb.GetUserTypesResponse.deserializeBinary
  );

  getUserTypes(
    request: usertype_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<usertype_pb.GetUserTypesResponse>;

  getUserTypes(
    request: usertype_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: usertype_pb.GetUserTypesResponse) => void): grpcWeb.ClientReadableStream<usertype_pb.GetUserTypesResponse>;

  getUserTypes(
    request: usertype_pb.GetUserTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: usertype_pb.GetUserTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user_type_endpoint.UserTypeEndpoint/GetUserTypes',
        request,
        metadata || {},
        this.methodDescriptorGetUserTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user_type_endpoint.UserTypeEndpoint/GetUserTypes',
    request,
    metadata || {},
    this.methodDescriptorGetUserTypes);
  }

}

