import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { GetRolesRequest, GetRolesResponse, DeleteRoleResponse, DeleteRoleRequest, AddRoleResponse } from '../../../../../repository/UserManagement/role_pb';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/RoleServiceClientPb';


export interface RoleDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: RoleDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const clientRole = new RoleEndpointClient(API_URL, null, null);


export const getRole = createAsyncThunk<GetRolesResponse.AsObject, APIRequest<GetRolesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientRole.getRoles(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const deleteRole = createAsyncThunk<APIResponse<DeleteRoleResponse.AsObject>, APIRequest<DeleteRoleRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/roleDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientRole.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const managersRoleDetailsPageSlice = createSlice({
    name: 'pages/management/roleDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetRole: (state) => {
            state.item = undefined;

        },

        updateRole: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRole.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload?.success?.rolesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isDefault: val.isdefault,
                        roleCategory: val.rolecategory,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])
                state.item = r.at(0);
               

            } 
        })
        builder.addCase(getRole.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getRole.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteRole.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetRole, updateRole } = managersRoleDetailsPageSlice.actions

export default managersRoleDetailsPageSlice.reducer