import { IBreadcrumbItem } from '@fluentui/react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { ApiMessage, APIRequest, APIResponse, API_URL, getSession, refreshSession, toApiMessage } from '../../../app/Api';
import { Page } from '../../../app/Pages';
import { OrganizationEndpointClient } from '../../../repository/UserManagement/OrganizationServiceClientPb';
import { GetOrganizationRequest, GetOrganizationResponse } from '../../../repository/UserManagement/organization_pb';





export interface MasterLayoutState {
  breadCrumbItem?: Page,
  currentOrganization: any,
  message?: ApiMessage,

}

const initialState: MasterLayoutState = {
  breadCrumbItem: undefined,
  currentOrganization: undefined
}

const client = new OrganizationEndpointClient(API_URL, null, null);

export const getOrganization = createAsyncThunk<APIResponse<GetOrganizationResponse.AsObject>, APIRequest<GetOrganizationRequest>, {
  rejectValue: ApiMessage
}>(
  'master/organizations/get',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.getOrganization(req.body, req.headers ?? {});
        return { metadata: { id: req.body.getId() }, response: r.toObject() };
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)



export const masterLayoutSlice = createSlice({
  name: 'masterLayout',
  initialState,
  reducers: {
    dismissMessage: (state) => {
      state.message = undefined;
    },
    setPage: (state, action: PayloadAction<Page>) => {
      state.breadCrumbItem = action.payload;
    },
    setOrganization: (state, action: PayloadAction<any>) => {
      state.currentOrganization = action.payload;

    },

    rest: (state) => {
      state.breadCrumbItem = initialState.breadCrumbItem
      state.message = initialState.message;
      state.currentOrganization = initialState.currentOrganization;

    }

  },
  extraReducers: (builder) => {

    builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
      if (payload) {
        let r = {
          id: payload.metadata?.id,
          domain: payload.response.success?.domain?.value ?? "",
          name: payload.response.success?.name?.value ?? "",
          logo: {} as any,
        }
        try {
          var logo = JSON.parse(payload.response.success?.logo?.value ?? "")
          r.logo = logo;
        } catch {

        }
        state.currentOrganization = r;
      }
    })
    builder.addCase(getOrganization.rejected, (state, action) => {
      if (action.payload) {
          state.message = action.payload;
      }
    })
    builder.addCase(getOrganization.pending, (state, action) => {


    })
  }
})

export const { setPage, rest, setOrganization } = masterLayoutSlice.actions

export default masterLayoutSlice.reducer


