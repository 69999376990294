import { Nav, Stack, initializeIcons, INavLinkGroup, IBreadcrumbItem, Breadcrumb, INavLink, ThemeProvider, Panel, Layer, Text, Link, Persona, PersonaSize, IconButton } from '@fluentui/react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { getPage } from '../../../app/Pages';
import { NavHeader } from '../../common/NavHeader/NavHeader';
import { setTitle } from '../../common/NavHeader/NavHeaderSlice';
import { currentOrganization, navDrawerStyle, navigatorLayout, pageSectionStyle } from './MasterLayout.styles';
import { useBoolean } from '@fluentui/react-hooks';
import { Settings } from '../../common/Settings/Settings';
import i18n from '../../../app/i18n';
import { useTranslation } from 'react-i18next';
import { Int32Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiMessage, API_URL, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, STORAGE_URL } from '../../../app/Api';
import { GetOrganizationRequest, GetOrganizationResponse } from '../../../repository/UserManagement/organization_pb';
import { getOrganization, rest, setOrganization } from './MasterLayoutSlice';
import { navHeaderStyle } from '../../common/NavHeader/NavHeader.styles';
import { SessionExpiredDialog } from '../../common/SessionExpiredDialog/SessionExpiredDialog';
import { normalizeKey } from '../../../app/Helpers';

initializeIcons();

const navLinkGroups = (t: any, org?: string): INavLinkGroup[] => {
  if (org) {
    return [
      {
        links: [
          {
            name: t("dashboard"),
            url: '/' + (org ? '?org=' + org : ''),
            key: 'dashboard',
            title: t("dashboard"),
          },
          {
            name: t("customers"),
            url: '/clients/users' + (org ? '?org=' + org : ''),
            expandAriaLabel: t("expandAriaLabelCustomers"),
            collapseAriaLabel: t("collapseAriaLabelCustomers"),
            title: t("customers"),

            links: [
              {
                name: t("profiles"),
                url: '/clients/users' + (org ? '?org=' + org : ''),
                key: 'clients/users',
                title: t("profiles"),
              },
              {
                name: t("privileges"),
                url: '/clients/privileges' + (org ? '?org=' + org : ''),
                key: 'clients/privileges',
                title: t("privileges"),
              },
              {
                name: t("logs"),
                url: '/clients/logs' + (org ? '?org=' + org : ''),
                key: 'clients/logs',
                title: t("logs"),
              },

            ],
            isExpanded: true,
          },
          {
            name: t("management"),
            url: '/customers/users' + (org ? '?org=' + org : ''),
            expandAriaLabel: t("expandAriaLabelCustomers"),
            collapseAriaLabel: t("collapseAriaLabelCustomers"),
            title: t("management"),

            links: [
              {
                name: t("users"),
                url: '/customers/users' + (org ? '?org=' + org : ''),
                key: 'customers/users',
                title: t("users"),

              },
              {
                name: t("usersTypes"),
                url: '/customers/user-types' + (org ? '?org=' + org : ''),
                key: 'customers/usersTypes',
                title: t("usersTypes"),
              },
              {
                name: t("roles"),
                url: '/customers/roles' + (org ? '?org=' + org : ''),
                key: 'customers/roles',
                title: t("roles"),
              },
              {
                name: t("groups"),
                url: '/customers/groups' + (org ? '?org=' + org : ''),
                key: 'customers/groups',
                title: t("groups"),
              },
              {
                name: t("privileges"),
                url: '/customers/privileges' + (org ? '?org=' + org : ''),
                key: 'customers/privileges',
                title: t("privileges"),
              },


            ],
            isExpanded: true,
          },



          {
            name: t("logs"),
            url: '/logs' + (org ? '?org=' + org : ''),
            key: 'logs',
            title: t("logs"),
          },
          {
            name: t("organizations"),
            url: '/organizations' + (org ? '?org=' + org : ''),
            key: 'organizations',
            title: t("organizations"),
          },
        ],
      },
    ];
  } else {
    return [
      {
        links: [
          {
            name: t("dashboard"),
            url: '/' + (org ? '?org=' + org : ''),
            key: 'dashboard',
            title: t("dashboard"),
          },


          {
            name: t("management"),
            url: '/management/users' + (org ? '?org=' + org : ''),
            expandAriaLabel: t("expandAriaLabelManagement"),
            collapseAriaLabel: t("collapseAriaLabelManagement"),
            title: t("management"),
            links: [
              {
                name: t("users"),
                url: '/management/users' + (org ? '?org=' + org : ''),
                key: 'management/users',
                title: t("users"),

              },
              {
                name: t("usersTypes"),
                url: '/management/user-types' + (org ? '?org=' + org : ''),
                key: 'management/usersTypes',
                title: t("usersTypes"),
              },
              {
                name: t("roles"),
                url: '/management/roles' + (org ? '?org=' + org : ''),
                key: 'management/roles',
                title: t("roles"),
              },
              {
                name: t("groups"),
                url: '/management/groups' + (org ? '?org=' + org : ''),
                key: 'management/groups',
                title: t("groups"),
              },
              {
                name: t("privileges"),
                url: '/management/privileges' + (org ? '?org=' + org : ''),
                key: 'management/privileges',
                title: t("privileges"),
              },



            ],
            isExpanded: true,
          },
          {
            name: t("logs"),
            url: '/logs' + (org ? '?org=' + org : ''),
            key: 'logs',
            title: t("logs"),
          },
          {
            name: t("organizations"),
            url: '/organizations' + (org ? '?org=' + org : ''),
            key: 'organizations',
            title: t("organizations"),
          },
        ],
      },
    ];
  }

}

//let req = new GetOrganizationRequest();
//let getOrganizationPromise: any;
let org: number = -1;
let promise: any;

export const MasterLayout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {

    let current = getSession();

    if (!current) {
      clearSession();
      navigate("/login");

    }

    var l = searchParams.get('org');
    if (l) {
      org = Number(l);
      if (Number.isNaN(org)) {
        org = -1;
      } else {
        if (state.organization == undefined || state.organization.id != org) {
          let getOrgReq = new GetOrganizationRequest()
          getOrgReq.setId(org)
          promise?.abort()
          promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
          promise.unwrap().catch((e: any) => {
            window.location.href = "/";
          })
        }
      }
    } else {
      //if (state.organization == undefined && current.organization?.id) {

      // let getOrgReq = new GetOrganizationRequest()
      // getOrgReq.setId(current.organization?.id)
      // promise?.abort()
      // promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
      // }
      org = -1;
    }
    return () => { //clean up
      dispatch(rest())
      promise?.abort();
      org = -1;
    }
  }, [])


  const handleBreadcrumbItemClick = (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
    if (ev && item) {
      ev.preventDefault();
      if (item.href)
        navigate(item.href + ((org == -1) ? "" : "?org=" + org));
    }
  }

  const handleNavClick = (event?: React.MouseEvent<HTMLElement>, element?: INavLink) => {
    if (event && element) {
      event.preventDefault();
      navigate(element.url);
    }
  }
  const state: { pages: IBreadcrumbItem[], organization: any, message: ApiMessage | undefined } = useAppSelector((state) => {
    var list: IBreadcrumbItem[] = [];

    var item = state.masterLayout.breadCrumbItem;
    while (item) {
      list.push({ text: t(normalizeKey(item.title)), key: item.key, data: item.nav, href: "/" + item.path, onClick: handleBreadcrumbItemClick });
      if (item.parentKey)
        item = getPage(item.parentKey);
      else {
        item = undefined;
        list[0].href = undefined; // current page not clickable
        list[0].onClick = undefined; // current page not clickable
      }
    }
    return {
      pages: list.reverse(), organization: state.masterLayout.currentOrganization, message: state.masterLayout.message,
    };
  })


  return (
    <Stack verticalFill horizontal >
      {state.message != undefined ? (state.message.data != 401) ? undefined :
        <SessionExpiredDialog />
        : null
      }
      <Settings />
      <Stack styles={navigatorLayout}>

        <Stack.Item grow styles={{ root: { overflow: 'auto' } }}>
          <Nav ariaLabel={t("breadcrumbAria")} styles={navDrawerStyle} groups={navLinkGroups(t, org == -1 ? undefined : org + "")} onLinkClick={handleNavClick} selectedKey={(state.pages.length > 0) ? state.pages[state.pages.length - 1].data : undefined} />
        </Stack.Item>
      </Stack>
      <Stack grow >

        <NavHeader />

        <Stack styles={pageSectionStyle}  >
          <Breadcrumb
            items={state.pages}
            ariaLabel={t("breadcrumbAria")}
            overflowAriaLabel={t("breadcrumbOverFlowAria")}
            focusZoneProps={{ handleTabKey: 1 }}
            styles={{ root: { padding: '0px 3% 0px 3%' } }}
          />

          <Outlet />

        </Stack>
      </Stack>
    </Stack >

  )
};
