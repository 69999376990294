import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession } from '../../../app/Api';
import { ProfileEndpointClient } from '../../../repository/UserManagement/ProfileServiceClientPb';
import { UpdatePasswordResponse, UpdateProfilePasswordRequest } from '../../../repository/UserManagement/profile_pb';

export interface RolesFormState {
  isLoading: boolean
  message?: ApiMessage
}

const initialState: RolesFormState = {
  isLoading: false,
  message: undefined
}

const client = new ProfileEndpointClient(API_URL, null, null);

export const update = createAsyncThunk<UpdatePasswordResponse.AsObject, APIRequest<UpdateProfilePasswordRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/changePassword/updatePassword',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.updatePassword(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const changePasswordFormSlice = createSlice({
  name: 'forms/changePassword',
  initialState,
  reducers: {

    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
  }
})

export const { reset, dismissMessage } = changePasswordFormSlice.actions

export default changePasswordFormSlice.reducer


