/**
 * @fileoverview gRPC-Web generated client stub for organization_member_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as organizationmember_pb from './organizationmember_pb';


export class OrganizationMemberEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.AddUserRequest,
    organizationmember_pb.AddUserResponse,
    (request: organizationmember_pb.AddUserRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.AddUserResponse.deserializeBinary
  );

  add(
    request: organizationmember_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.AddUserResponse>;

  add(
    request: organizationmember_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.AddUserResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.AddUserResponse>;

  add(
    request: organizationmember_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.AddUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.UpdateUserRequest,
    organizationmember_pb.UpdateUserResponse,
    (request: organizationmember_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.UpdateUserResponse.deserializeBinary
  );

  update(
    request: organizationmember_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.UpdateUserResponse>;

  update(
    request: organizationmember_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.UpdateUserResponse>;

  update(
    request: organizationmember_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.UpdateUserActivityRequest,
    organizationmember_pb.UpdateUserActivityResponse,
    (request: organizationmember_pb.UpdateUserActivityRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.UpdateUserActivityResponse.deserializeBinary
  );

  updateActivity(
    request: organizationmember_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.UpdateUserActivityResponse>;

  updateActivity(
    request: organizationmember_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserActivityResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.UpdateUserActivityResponse>;

  updateActivity(
    request: organizationmember_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorUpdateConfirmationState = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateConfirmationState',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.UpdateUserConfirmationStateRequest,
    organizationmember_pb.UpdateUserConfirmationStateResponse,
    (request: organizationmember_pb.UpdateUserConfirmationStateRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.UpdateUserConfirmationStateResponse.deserializeBinary
  );

  updateConfirmationState(
    request: organizationmember_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.UpdateUserConfirmationStateResponse>;

  updateConfirmationState(
    request: organizationmember_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserConfirmationStateResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.UpdateUserConfirmationStateResponse>;

  updateConfirmationState(
    request: organizationmember_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUserConfirmationStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateConfirmationState',
        request,
        metadata || {},
        this.methodDescriptorUpdateConfirmationState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateConfirmationState',
    request,
    metadata || {},
    this.methodDescriptorUpdateConfirmationState);
  }

  methodDescriptorUpdateAccountSecurity = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateAccountSecurity',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.UpdateAccountSecurityRequest,
    organizationmember_pb.UpdateAccountSecurityResponse,
    (request: organizationmember_pb.UpdateAccountSecurityRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.UpdateAccountSecurityResponse.deserializeBinary
  );

  updateAccountSecurity(
    request: organizationmember_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: organizationmember_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateAccountSecurityResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: organizationmember_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateAccountSecurityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateAccountSecurity',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccountSecurity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateAccountSecurity',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccountSecurity);
  }

  methodDescriptorUpdateUsername = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateUsername',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.UpdateUsernameRequest,
    organizationmember_pb.UpdateUsernameResponse,
    (request: organizationmember_pb.UpdateUsernameRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.UpdateUsernameResponse.deserializeBinary
  );

  updateUsername(
    request: organizationmember_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.UpdateUsernameResponse>;

  updateUsername(
    request: organizationmember_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUsernameResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.UpdateUsernameResponse>;

  updateUsername(
    request: organizationmember_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.UpdateUsernameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateUsername',
        request,
        metadata || {},
        this.methodDescriptorUpdateUsername,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/UpdateUsername',
    request,
    metadata || {},
    this.methodDescriptorUpdateUsername);
  }

  methodDescriptorGetUsers = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/GetUsers',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.GetUsersRequest,
    organizationmember_pb.GetUsersResponse,
    (request: organizationmember_pb.GetUsersRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.GetUsersResponse.deserializeBinary
  );

  getUsers(
    request: organizationmember_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.GetUsersResponse>;

  getUsers(
    request: organizationmember_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.GetUsersResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.GetUsersResponse>;

  getUsers(
    request: organizationmember_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.GetUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/GetUsers',
        request,
        metadata || {},
        this.methodDescriptorGetUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/GetUsers',
    request,
    metadata || {},
    this.methodDescriptorGetUsers);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/GetUser',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.GetUserRequest,
    organizationmember_pb.GetUserResponse,
    (request: organizationmember_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.GetUserResponse.deserializeBinary
  );

  getUser(
    request: organizationmember_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.GetUserResponse>;

  getUser(
    request: organizationmember_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.GetUserResponse>;

  getUser(
    request: organizationmember_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorResetUserPassword = new grpcWeb.MethodDescriptor(
    '/organization_member_endpoint.OrganizationMemberEndpoint/ResetUserPassword',
    grpcWeb.MethodType.UNARY,
    organizationmember_pb.ResetUserPasswordRequest,
    organizationmember_pb.ResetUserPasswordResponse,
    (request: organizationmember_pb.ResetUserPasswordRequest) => {
      return request.serializeBinary();
    },
    organizationmember_pb.ResetUserPasswordResponse.deserializeBinary
  );

  resetUserPassword(
    request: organizationmember_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationmember_pb.ResetUserPasswordResponse>;

  resetUserPassword(
    request: organizationmember_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationmember_pb.ResetUserPasswordResponse) => void): grpcWeb.ClientReadableStream<organizationmember_pb.ResetUserPasswordResponse>;

  resetUserPassword(
    request: organizationmember_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationmember_pb.ResetUserPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_member_endpoint.OrganizationMemberEndpoint/ResetUserPassword',
        request,
        metadata || {},
        this.methodDescriptorResetUserPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_member_endpoint.OrganizationMemberEndpoint/ResetUserPassword',
    request,
    metadata || {},
    this.methodDescriptorResetUserPassword);
  }

}

