// source: privilege.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.clients_privilege_endpoint.AddPrivilegeReplay', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.AddPrivilegeRequest', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.AddPrivilegeResponse', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.AddPrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegeReplay', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegeRequest', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegeResponse', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegeResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegesReplay', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegesRequest', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegesResponse', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.GetPrivilegesResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.PrivilegeSummaryInfo', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeReplay', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeRequest', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeResponse', null, global);
goog.exportSymbol('proto.clients_privilege_endpoint.UpdatePrivilegeResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.AddPrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.AddPrivilegeRequest.displayName = 'proto.clients_privilege_endpoint.AddPrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeRequest.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegesRequest.displayName = 'proto.clients_privilege_endpoint.GetPrivilegesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegeRequest.displayName = 'proto.clients_privilege_endpoint.GetPrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.AddPrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.AddPrivilegeReplay.displayName = 'proto.clients_privilege_endpoint.AddPrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeReplay.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_privilege_endpoint.GetPrivilegesReplay.repeatedFields_, null);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegesReplay.displayName = 'proto.clients_privilege_endpoint.GetPrivilegesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.PrivilegeSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.PrivilegeSummaryInfo.displayName = 'proto.clients_privilege_endpoint.PrivilegeSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegeReplay.displayName = 'proto.clients_privilege_endpoint.GetPrivilegeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_privilege_endpoint.AddPrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.clients_privilege_endpoint.AddPrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.AddPrivilegeResponse.displayName = 'proto.clients_privilege_endpoint.AddPrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_privilege_endpoint.UpdatePrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeResponse.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.oneofGroups_);
};
goog.inherits(proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.displayName = 'proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_privilege_endpoint.GetPrivilegesResponse.oneofGroups_);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegesResponse.displayName = 'proto.clients_privilege_endpoint.GetPrivilegesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_privilege_endpoint.GetPrivilegeResponse.oneofGroups_);
};
goog.inherits(proto.clients_privilege_endpoint.GetPrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_privilege_endpoint.GetPrivilegeResponse.displayName = 'proto.clients_privilege_endpoint.GetPrivilegeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.AddPrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    classname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    methodname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.AddPrivilegeRequest;
  return proto.clients_privilege_endpoint.AddPrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodname(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.AddPrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClassname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethodname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyName = 2;
 * @return {string}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.getKeyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.setKeyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string className = 3;
 * @return {string}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.getClassname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.setClassname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string methodName = 4;
 * @return {string}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.getMethodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.setMethodname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    classname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    methodname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeRequest;
  return proto.clients_privilege_endpoint.UpdatePrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClassname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethodname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyName = 2;
 * @return {string}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.getKeyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.setKeyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string className = 3;
 * @return {string}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.getClassname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.setClassname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string methodName = 4;
 * @return {string}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.getMethodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.setMethodname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest;
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool isAvailable = 1;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isadded: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegesRequest;
  return proto.clients_privilege_endpoint.GetPrivilegesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsadded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIsadded();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 nextTo = 3;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getNextto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool isAdded = 6;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.getIsadded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesRequest.prototype.setIsadded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeRequest}
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegeRequest;
  return proto.clients_privilege_endpoint.GetPrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeRequest}
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeRequest} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.AddPrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.AddPrivilegeReplay;
  return proto.clients_privilege_endpoint.AddPrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegeid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.AddPrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 privilegeId = 1;
 * @return {number}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.getPrivilegeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.setPrivilegeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.AddPrivilegeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeReplay;
  return proto.clients_privilege_endpoint.UpdatePrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay;
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    privilegesList: jspb.Message.toObjectList(msg.getPrivilegesList(),
    proto.clients_privilege_endpoint.PrivilegeSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegesReplay;
  return proto.clients_privilege_endpoint.GetPrivilegesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.PrivilegeSummaryInfo;
      reader.readMessage(value,proto.clients_privilege_endpoint.PrivilegeSummaryInfo.deserializeBinaryFromReader);
      msg.addPrivileges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivilegesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.clients_privilege_endpoint.PrivilegeSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PrivilegeSummaryInfo privileges = 1;
 * @return {!Array<!proto.clients_privilege_endpoint.PrivilegeSummaryInfo>}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.getPrivilegesList = function() {
  return /** @type{!Array<!proto.clients_privilege_endpoint.PrivilegeSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.clients_privilege_endpoint.PrivilegeSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.clients_privilege_endpoint.PrivilegeSummaryInfo>} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.setPrivilegesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo}
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.addPrivileges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.clients_privilege_endpoint.PrivilegeSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesReplay.prototype.clearPrivilegesList = function() {
  return this.setPrivilegesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.PrivilegeSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    keyname: (f = msg.getKeyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.PrivilegeSummaryInfo;
  return proto.clients_privilege_endpoint.PrivilegeSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setKeyname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.PrivilegeSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getKeyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProfileResponse addedBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
*/
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue keyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getKeyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
*/
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setKeyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.clearKeyname = function() {
  return this.setKeyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.hasKeyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
*/
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
*/
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.PrivilegeSummaryInfo} returns this
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.PrivilegeSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    keyname: (f = msg.getKeyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    classname: (f = msg.getClassname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    methodname: (f = msg.getMethodname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    privilegecategory: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegeReplay;
  return proto.clients_privilege_endpoint.GetPrivilegeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setKeyname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClassname(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMethodname(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrivilegecategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getKeyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClassname();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMethodname();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivilegecategory();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.ProfileResponse addedBy = 2;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 2));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue keyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getKeyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setKeyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearKeyname = function() {
  return this.setKeyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasKeyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue className = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getClassname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setClassname = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearClassname = function() {
  return this.setClassname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasClassname = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue methodName = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getMethodname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setMethodname = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.clearMethodname = function() {
  return this.setMethodname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.hasMethodname = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 privilegeCategory = 9;
 * @return {number}
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.getPrivilegecategory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeReplay} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeReplay.prototype.setPrivilegecategory = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_privilege_endpoint.AddPrivilegeResponse.ResultCase}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_privilege_endpoint.AddPrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_privilege_endpoint.AddPrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.AddPrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_privilege_endpoint.AddPrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeResponse}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.AddPrivilegeResponse;
  return proto.clients_privilege_endpoint.AddPrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeResponse}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.AddPrivilegeReplay;
      reader.readMessage(value,proto.clients_privilege_endpoint.AddPrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.AddPrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.AddPrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_privilege_endpoint.AddPrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddPrivilegeReplay success = 1;
 * @return {?proto.clients_privilege_endpoint.AddPrivilegeReplay}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_privilege_endpoint.AddPrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_privilege_endpoint.AddPrivilegeReplay, 1));
};


/**
 * @param {?proto.clients_privilege_endpoint.AddPrivilegeReplay|undefined} value
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeResponse} returns this
*/
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_privilege_endpoint.AddPrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.AddPrivilegeResponse} returns this
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.AddPrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_privilege_endpoint.UpdatePrivilegeResponse.ResultCase}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_privilege_endpoint.UpdatePrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_privilege_endpoint.UpdatePrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_privilege_endpoint.UpdatePrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeResponse;
  return proto.clients_privilege_endpoint.UpdatePrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.UpdatePrivilegeReplay;
      reader.readMessage(value,proto.clients_privilege_endpoint.UpdatePrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_privilege_endpoint.UpdatePrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdatePrivilegeReplay success = 1;
 * @return {?proto.clients_privilege_endpoint.UpdatePrivilegeReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_privilege_endpoint.UpdatePrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_privilege_endpoint.UpdatePrivilegeReplay, 1));
};


/**
 * @param {?proto.clients_privilege_endpoint.UpdatePrivilegeReplay|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_privilege_endpoint.UpdatePrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeResponse} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.ResultCase}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse;
  return proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay;
      reader.readMessage(value,proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdatePrivilegeAvailabilityReplay success = 1;
 * @return {?proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay, 1));
};


/**
 * @param {?proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityReplay|undefined} value
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse} returns this
*/
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse} returns this
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.UpdatePrivilegeAvailabilityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_privilege_endpoint.GetPrivilegesResponse.ResultCase}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_privilege_endpoint.GetPrivilegesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_privilege_endpoint.GetPrivilegesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_privilege_endpoint.GetPrivilegesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesResponse}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegesResponse;
  return proto.clients_privilege_endpoint.GetPrivilegesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesResponse}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.GetPrivilegesReplay;
      reader.readMessage(value,proto.clients_privilege_endpoint.GetPrivilegesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_privilege_endpoint.GetPrivilegesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPrivilegesReplay success = 1;
 * @return {?proto.clients_privilege_endpoint.GetPrivilegesReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_privilege_endpoint.GetPrivilegesReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_privilege_endpoint.GetPrivilegesReplay, 1));
};


/**
 * @param {?proto.clients_privilege_endpoint.GetPrivilegesReplay|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesResponse} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_privilege_endpoint.GetPrivilegesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegesResponse} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_privilege_endpoint.GetPrivilegeResponse.ResultCase}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_privilege_endpoint.GetPrivilegeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_privilege_endpoint.GetPrivilegeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_privilege_endpoint.GetPrivilegeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_privilege_endpoint.GetPrivilegeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeResponse}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_privilege_endpoint.GetPrivilegeResponse;
  return proto.clients_privilege_endpoint.GetPrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeResponse}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_privilege_endpoint.GetPrivilegeReplay;
      reader.readMessage(value,proto.clients_privilege_endpoint.GetPrivilegeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_privilege_endpoint.GetPrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_privilege_endpoint.GetPrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_privilege_endpoint.GetPrivilegeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPrivilegeReplay success = 1;
 * @return {?proto.clients_privilege_endpoint.GetPrivilegeReplay}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_privilege_endpoint.GetPrivilegeReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_privilege_endpoint.GetPrivilegeReplay, 1));
};


/**
 * @param {?proto.clients_privilege_endpoint.GetPrivilegeReplay|undefined} value
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeResponse} returns this
*/
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_privilege_endpoint.GetPrivilegeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_privilege_endpoint.GetPrivilegeResponse} returns this
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_privilege_endpoint.GetPrivilegeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.clients_privilege_endpoint);
