import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import { mergeStyles } from '@fluentui/react';
import { App } from './app';
import { Provider } from 'react-redux';
import { store } from './app/Store';
import './app/i18n';

mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
    overflow: "hidden"
  },
});

document.getElementsByTagName('html')[0].setAttribute("dir", (localStorage.getItem("lang") == "en")? "ltr" : "rtl");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>

);
/*
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode >,
  document.getElementById('root')
);



*/