import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../repository/UserManagement/Clients/ClientServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession,  APIRequest } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { number } from 'yup';
import { LogEndpointClient } from '../../../../../repository/UserManagement/Clients/LogServiceClientPb';
import { GetLogsResponse, GetLogsRequest } from '../../../../../repository/UserManagement/Clients/log_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../../../repository/UserManagement/Clients/client_pb';
import { GetPrivilegesResponse, GetPrivilegesRequest } from '../../../../../repository/UserManagement/Clients/privilege_pb';
import { PrivilegeEndpointClient } from '../../../../../repository/UserManagement/Clients/PrivilegeServiceClientPb';


export interface LogsPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    executers: TableState
    privileges: TableState
    objectIdFilterOption: { value: string, text: string },
    typeFilterOption: { key: number, text: string },
    fromFilterOption: { value: string, text: string },
    toFilterOption: { value: string, text: string },


}

const initialState: LogsPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    executers: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    privileges: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    objectIdFilterOption: { value: "", text: "" },
    typeFilterOption: { key: 0, text: "" },
    fromFilterOption: { value: "", text: "" },
    toFilterOption: { value: "", text: "" },

}

const client = new LogEndpointClient(API_URL, null, null);
const clienManagers = new UserEndpointClient(API_URL, null, null);
const clienPrivileges = new PrivilegeEndpointClient(API_URL, null, null);


export const getItems = createAsyncThunk<GetLogsResponse.AsObject, APIRequest<GetLogsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/logs/clients/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getLogs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/logs/clients/users/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienManagers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getPrivileges = createAsyncThunk<GetPrivilegesResponse.AsObject, APIRequest<GetPrivilegesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/logs/clients/privileges/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienPrivileges.getPrivileges(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



const getFilters = (state: LogsPageState): any[] => {
    const selected = [];
    if (state.executers.selected.length > 0) {
        selected.push({ key: 1, name: state.executers.selected.at(0).name });
    }
    if (state.privileges.selected.length > 0) {
        selected.push({ key: 2, name: state.privileges.selected.at(0).name });
    }

    if (state.objectIdFilterOption.value && state.objectIdFilterOption.value != "") {
        selected.push({ key: 3, name: state.objectIdFilterOption.text });
    }
    if (state.typeFilterOption.key != 0) {
        selected.push({ key: 4, name: state.typeFilterOption.text });
    }

    if (state.fromFilterOption.value && state.fromFilterOption.value != "") {
        selected.push({ key: 5, name: state.fromFilterOption.text });
    }
    if (state.toFilterOption.value && state.toFilterOption.value != "") {
        selected.push({ key: 6, name: state.toFilterOption.text });
    }


    return selected;
}

export const clientLogsPageSlice = createSlice({
    name: 'pages/logs/clients',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.executers = initialState.executers;
            state.objectIdFilterOption = initialState.objectIdFilterOption;
            state.privileges = initialState.privileges;
            state.typeFilterOption = initialState.typeFilterOption;
            state.filters = initialState.filters;
            state.fromFilterOption = initialState.fromFilterOption;
            state.toFilterOption = initialState.toFilterOption;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        resetUsers: (state) => {
            state.executers.items = [];
            state.executers.hasMore = true;
        },
        resetPrivileges: (state) => {
            state.privileges.items = [];
            state.privileges.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },
        setSelectedPrivilegeItems: (state, action: PayloadAction<any[]>) => {
            state.privileges.selected = action.payload;
            state.filters = getFilters(state);

        },

        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.executers.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        setIsFilteredPrivilegeSet: (state, action: PayloadAction<boolean>) => {
            state.privileges.isFilteredSet = action.payload;
        },

        setIsFilteredUserSet: (state, action: PayloadAction<boolean>) => {
            state.executers.isFilteredSet = action.payload;
        },
        setTypeFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.typeFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setObjectIdFilterOption: (state, action: PayloadAction<{ value: string, text: string  }>) => {
            state.objectIdFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setFromFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.fromFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setToFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.toFilterOption = action.payload;
            state.filters = getFilters(state);
        },

        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getPrivileges.fulfilled, (state, { payload }) => {
            state.privileges.isFetching = false
            if (payload) {
                var r = (payload?.success?.privilegesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        keyName: val.keyname?.value ?? "",
                        isAvailable: val.isavailable,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.privileges.numberOfResults) {
                    state.privileges.hasMore = false;
                }
                state.privileges.items = state.privileges.items.concat(r);

            } else {
                state.privileges.hasMore = false;
            }
        })
        builder.addCase(getPrivileges.rejected, (state, action) => {
            state.privileges.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getPrivileges.pending, (state, action) => {
            state.privileges.isFetching = true;
            // state.message = undefined;
        })




        builder.addCase(getUsers.fulfilled, (state, { payload }) => {
            state.executers.isFetching = false
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.id,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.executers.numberOfResults) {
                    state.executers.hasMore = false;
                }
                state.executers.items = state.executers.items.concat(r);
                //console.log(state.items)

            } else {
                state.executers.hasMore = false;
            }
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.executers.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getUsers.pending, (state, action) => {
            state.executers.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            state.set.isFetching = false
            if (payload) {
                var r = (payload?.success?.logsList.map(val => {
                    /*  id: val.id,
                        objectid: val.objectid?.value,
                        description: val.description?.value ?? "",
                        privilegeId: val.action?.id?.value,
                        privilegeName: val.action?.name?.value,
                        privilegeEndpoint: val.action?.endpoint?.value,
                        privilegeClass: val.action?.pb_class?.value,
                        endExecutionDate: formatDate(timestampToDate(val.endexecutiondate?.seconds, val.endexecutiondate?.nanos)),
                        startExecutionDate: formatDate(timestampToDate(val.startexecutiondate?.seconds, val.startexecutiondate?.nanos)),
                        type: val.type?.value,
                        clientIP: val.client?.ip?.value ?? "",
                        userAgent: val.client?.useragent?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.executer?.firstname?.value ?? "") + " " + (val.executer?.lastname?.value ?? ""),
                        addedById: val.executer?.id?.value, */
                    return {
                        id: val.id,
                        objectid: val.objectid?.value,
                        privilegeId: val.action?.id?.value,
                        privilegeName: val.action?.name?.value,
                        privilegeEndpoint: val.action?.endpoint?.value,
                        privilegeClass: val.action?.pb_class?.value,
                        type: val.type?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.executer?.firstname?.value ?? "") + " " + (val.executer?.lastname?.value ?? ""),
                        addedById: val.executer?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })



    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setFromFilterOption, setToFilterOption, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetPrivileges, resetUsers, setIsFilteredPrivilegeSet, setIsFilteredUserSet, setObjectIdFilterOption, setSelectedPrivilegeItems, setSelectedUserItems, setTypeFilterOption } = clientLogsPageSlice.actions

export default clientLogsPageSlice.reducer


