/**
 * @fileoverview gRPC-Web generated client stub for organization_client_portal_setting_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as organizationclientportalsetting_pb from './organizationclientportalsetting_pb';


export class OrganizationClientPortalSettingEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateStatus = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateStatus',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateStatusRequest,
    organizationclientportalsetting_pb.UpdateStatusResponse,
    (request: organizationclientportalsetting_pb.UpdateStatusRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateStatusResponse.deserializeBinary
  );

  updateStatus(
    request: organizationclientportalsetting_pb.UpdateStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateStatusResponse>;

  updateStatus(
    request: organizationclientportalsetting_pb.UpdateStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateStatusResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateStatusResponse>;

  updateStatus(
    request: organizationclientportalsetting_pb.UpdateStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateStatus);
  }

  methodDescriptorGetOrganizationClientPortalSetting = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse,
    (request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse.deserializeBinary
  );

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse>;

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse>;

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
        request,
        metadata || {},
        this.methodDescriptorGetOrganizationClientPortalSetting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
    request,
    metadata || {},
    this.methodDescriptorGetOrganizationClientPortalSetting);
  }

}

