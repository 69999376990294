import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { OrganizationClientPortalSettingEndpointClient } from '../../../../repository/UserManagement/OrganizationclientportalsettingServiceClientPb';
import { GetOrganizationClientPortalSettingRequest, GetOrganizationClientPortalSettingResponse, UpdateStatusRequest, UpdateStatusResponse } from '../../../../repository/UserManagement/organizationclientportalsetting_pb';
import { OrganizationEndpointClient } from '../../../../repository/UserManagement/OrganizationServiceClientPb';
import { GetOrganizationResponse, UpdateOrganizationActivityResponse, UpdateOrganizationStateRequest, GetOrganizationRequest } from '../../../../repository/UserManagement/organization_pb';


export interface OrganizationDetailsPageState {
    item: any
    portalSetting: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: OrganizationDetailsPageState = {
    item: undefined,
    portalSetting: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new OrganizationEndpointClient(API_URL, null, null);
const clientPortal = new OrganizationClientPortalSettingEndpointClient(API_URL, null, null);

export const getOrganizationClientPortalSetting = createAsyncThunk<GetOrganizationClientPortalSettingResponse.AsObject, APIRequest<GetOrganizationClientPortalSettingRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationDetails/organizationClientPortalSetting/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientPortal.getOrganizationClientPortalSetting(req.body, req.headers ?? {});
                return r.toObject() ;

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    message.data =req.body.getId()
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getOrganization = createAsyncThunk<GetOrganizationResponse.AsObject, APIRequest<GetOrganizationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getOrganization(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateStatus = createAsyncThunk<APIResponse<UpdateOrganizationActivityResponse.AsObject>, APIRequest<UpdateOrganizationStateRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationDetails/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updatePortalStatus = createAsyncThunk<APIResponse<UpdateStatusResponse.AsObject>, APIRequest<UpdateStatusRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationDetails/organizationClientPortalSetting/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientPortal.updateStatus(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const managersOrganizationDetailsPageSlice = createSlice({
    name: 'pages/organizationDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.portalSetting = initialState.portalSetting
        },

        resetOrganization: (state) => {
            state.item = undefined;
            state.portalSetting = undefined;

        },

        updateOrganization: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                var r = {
                    id: payload.success?.id,
                    name: (payload.success?.name?.value ?? ""),
                    domain: payload.success?.domain?.value ?? "",
                    isActive: payload.success?.isactive,
                    address: payload.success?.address?.value ?? "",
                    email: payload.success?.email?.value ?? "",
                    phonenumber: payload.success?.phonenumber?.value ?? "",
                    logo: {} as any,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.addedby?.firstname?.value ?? "") + " " + (payload.success?.addedby?.lastname?.value ?? ""),
                    addedById: payload.success?.addedby?.id?.value,
                    parentOrganizationId: (payload.success?.parentorganization) ? payload.success?.parentorganization?.id : undefined,
                    parentOrganizationName: (payload.success?.parentorganization) ? payload.success?.parentorganization?.name?.value : undefined,
                    subscription: payload.success?.subscription?.value,


                }
                try {
                    var logo = JSON.parse(payload.success?.logo?.value ?? "")
                    r.logo = logo;
                } catch {

                }
                state.item = r;

            }
        })
        builder.addCase(getOrganization.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getOrganization.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload?.metadata.state
            }

        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updatePortalStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.portalSetting.isActive = payload?.metadata.state
            }

        })
        builder.addCase(updatePortalStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updatePortalStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(getOrganizationClientPortalSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                var r = {
                    id: payload?.success?.id,
                    isActive: payload.success?.isactive?.value,

                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,

                }
               
                state.portalSetting = r;

            }
        })
        builder.addCase(getOrganizationClientPortalSetting.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                var r = {
                    id: action.payload?.data,
                    isActive: false,
                }
               
                state.portalSetting = r;
               // state.message = action.payload;
            }
        })
        builder.addCase(getOrganizationClientPortalSetting.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetOrganization, updateOrganization } = managersOrganizationDetailsPageSlice.actions

export default managersOrganizationDetailsPageSlice.reducer