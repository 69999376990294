import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, getManager, getOrganization, getRoles, getUserTypes, OrganizationState, reset, resetRoles, resetUserTypes, setCurrentOrganization, setIsFilteredRolesSet, setSelectedRole, setSelectedUserType, setStage1, setStage2, setStage3, update } from "./UsersFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, phoneNumberFormatter, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { AddUserRequest, AddUserResponse, GetUserRequest, UpdateUserRequest, UpdateUserResponse } from "../../../../repository/UserManagement/user_pb";

import { Address, ExtraField } from "../../../../repository/UserManagement/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetUserTypesRequest } from "../../../../repository/UserManagement/usertype_pb";
import { GetRolesRequest } from "../../../../repository/UserManagement/role_pb";
import { setIsFilteredUserTypesSet } from "../../../pages/Management/Users/UsersPageSlice";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { GetOrganizationRequest } from "../../../../repository/UserManagement/organization_pb";
import { countriesAR, countriesEn } from "../../../../app/Content";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { RolesPage } from "../../../pages/Management/Roles/RolesPage";
import { UserTypesPage } from "../../../pages/Management/UserTypes/UserTypesPage";


let req: AddUserRequest;
let updateReq: UpdateUserRequest;

let roleReq: GetRolesRequest;
let userTypeReq: GetUserTypesRequest;

let getRolesPromise: any;
let getUserTypesPromise: any;

let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const UsersForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const [selectedKey, setSelectedKey] = useState(0);
    const onNextClick = () => {
        setSelectedKey((selectedKey + 1) % 3);
    };
    const onBackClick = () => {
        setSelectedKey((selectedKey - 1) % 3);
    };

    const state: { isLoading: boolean, message: ApiMessage | undefined, organization?: OrganizationState, stage1: any, stage2: any, stage3: any } = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading, message: state.managersUsersForm.message, organization: state.managersUsersForm.currentOrganization
            , stage1: state.managersUsersForm.stage1
            , stage2: state.managersUsersForm.stage2
            , stage3: state.managersUsersForm.stage3

        }
    })


    useEffect(() => {

        req = new AddUserRequest();
        updateReq = new UpdateUserRequest();
        roleReq = new GetRolesRequest();
        userTypeReq = new GetUserTypesRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {

            var l = searchParams.get('org');
            let getOrgReq = new GetOrganizationRequest()
            if (l) {
                let org = Number(l);
                if (!Number.isNaN(org)) {
                    if (props.type == FormType.ADD) {
                        getOrgReq.setId(org)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        /*promise.unwrap().catch((e: any) => {
                            if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                                getOrgReq.setId(current.organization?.id)
                                promise?.abort()
                                promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                                promise.unwrap().catch((e: any) => {
                                    dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                                })
                            } else {
                                dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                            }
                        })*/
                    } else {
                        dispatch(setCurrentOrganization({ id: org, domain: "", name: "" }));
                    }

                }
            } else {
                if (props.type == FormType.ADD) {
                    /*if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                        getOrgReq.setId(current.organization?.id)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        promise.unwrap().catch((e: any) => {
                            dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                        })
                    } else {
                        dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                    }
                } else {
                    dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                }*/
                }
            }

            if (props.type == FormType.EDIT) {
                if (props.fetchData) {
                    let r = new GetUserRequest()
                    r.setId(props?.renderObject?.internalId);
                    promise?.abort()
                    promise = dispatch(getManager({ body: r, headers: getHeaders() }))
                    promise.unwrap().catch((e: any) => {
                        setSelectedKey(3);
                    })
                } else {
                    dispatch(setStage1(props?.renderObject))
                    dispatch(setStage2(props?.renderObject))
                    dispatch(setStage3(props?.renderObject))
                }
            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    useEffect(() => {
        if (state.organization) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.organization?.id);
            /*req.setOrganization(wrapper)
            roleReq.setOrganization(wrapper)
            userTypeReq.setOrganization(wrapper)*/
        }
    }, [state.organization])

    return (
        <>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()); if (selectedKey == 3) props.onCancel() }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            {selectedKey <= 2 ? <Pivot aria-label={props.type == FormType.ADD ? t("addManager") : t("editManager")} selectedKey={String(selectedKey)} >
                <PivotItem headerText={t("managerInfo")} itemKey="0" >
                    <UsersGeneralInfoForm onSuccess={(e) => {
                        dispatch(setStage1({ ...state.stage1, ...e }))
                        onNextClick();
                    }} onCancel={props.onCancel} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage1} />
                </PivotItem>
                <PivotItem headerText={t("accountInfo")} itemKey="1">
                    <UsersAccountInfoForm onSuccess={(e) => {

                 
                        if (props.onSuccess) {
                            if (props.type == FormType.ADD)
                                props.onSuccess({ ...state.stage1, ...e })
                            else
                                props.onSuccess({ ...state.stage1, ...e })

                        }
                    }} onCancel={(e) => {
                        dispatch(setStage2({ ...state.stage2, ...e }))
                        onBackClick()
                    }} type={props.type} childrenGap={props.childrenGap} maxHeight={props.maxHeight}
                        maxWidth={props.maxWidth} padding={props.padding} renderObject={state.stage2} />
                </PivotItem>
            

            </Pivot> : undefined}
        </>
    );

}




export const UsersGeneralInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [countries, setCountries] = useState([] as IComboBoxOption[])
    const state: { isLoading: boolean } = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading,
        }
    })

    useEffect(() => {
        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
        } else {
            setCountries(countriesAR)
        }

    }, [])

    return (
        <>
            <Formik

                enableReinitialize
                initialValues={{

                    firstname: (props.renderObject?.firstname) ? props.renderObject.firstname : '',
                    lastname: (props.renderObject?.lastname) ? props.renderObject.lastname : '',
                    phoneNumbers: (props.renderObject?.phoneNumbers) ? props.renderObject.phoneNumbers as DynamicField[] : [] as DynamicField[],
                    emailAddresses: (props.renderObject?.emailAddresses) ? props.renderObject.emailAddresses as DynamicField[] : [] as DynamicField[],
                    isConfirmed: (props.renderObject) ? props.renderObject.isConfirmed : false,
                 

                }}




                validationSchema={Yup.object({

                    firstname: inputs.firstname,
                    lastname: inputs.lastname,
                    phoneNumbers: inputs.phoneNumbers,
                    emailAddresses: inputs.emailAddresses,


                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        if (props.onSuccess) {
                            props.onSuccess({
                                firstname: values.firstname.trim(),
                                lastname: values.lastname.trim(),
                                name: values.firstname.trim() + " " + values.lastname.trim(),
                                phoneNumbers: values.phoneNumbers,
                                emailAddresses: values.emailAddresses,
                                isConfirmed: values.isConfirmed
                            });
                        }
                        actions.setSubmitting(false)
                    } else {
                        if (props.onSuccess) {
                            props.onSuccess({
                                firstname: values.firstname.trim(),
                                lastname: values.lastname.trim(),
                                name: values.firstname.trim() + " " + values.lastname.trim(),
                                phoneNumbers: values.phoneNumbers,
                                emailAddresses: values.emailAddresses,
                  
                            });
                        }
                        actions.setSubmitting(false)
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Field name="firstname" label={t("firstname")} placeholder={t("firstname")} component={InputField} disabled={state.isLoading} autoFocus maxLength={50} required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="lastname" label={t("lastname")} placeholder={t("lastname")} component={InputField} disabled={state.isLoading} maxLength={50} required />
                                </Stack.Item>
                            </Stack>


                        

                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Label disabled={state.isLoading}>{t("emailAddresses")}</Label>
                                    <FieldArray
                                        name="emailAddresses"
                                    >
                                        {(arrayHelpers) => {
                                            let r = [] as React.ReactNode[];
                                            if (formkikProps.values.emailAddresses && formkikProps.values.emailAddresses.length > 0) {
                                                r = formkikProps.values.emailAddresses.map((o, index) => {
                                                    if (o.type == 1) {
                                                        return (<Stack horizontal key={index}>
                                                            {o.label != undefined ? <DynamicInputField name={`emailAddresses[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                                maxLength={50} /> : null}
                                                            <DynamicInputField name={`emailAddresses[${index}].value`} placeholder={"abc@gmail.com"} disabled={state.isLoading}
                                                                maxLength={50} dir="ltr" autoFocus={(o as any)?.autoFocus} type="email"
                                                            />

                                                            <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                        </Stack>)
                                                    }
                                                }
                                                )
                                            }
                                            if (formkikProps.values.emailAddresses.length <= 3) {
                                                r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.emailAddresses.length + 1) + "", label: undefined, type: 1, value: "", autoFocus: true })
                                                }} />)
                                            }
                                            return r;
                                        }

                                        }

                                    </FieldArray>
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Label disabled={state.isLoading} >{t("phonenumbers")}</Label>
                                    <FieldArray
                                        name="phoneNumbers"
                                    >
                                        {arrayHelpers => {
                                            let r = [] as React.ReactNode[];
                                            if (formkikProps.values.phoneNumbers && formkikProps.values.phoneNumbers.length > 0) {
                                                r = formkikProps.values.phoneNumbers.map((o, index) => {
                                                    if (o.type == 1) {
                                                        return (<Stack horizontal key={index}>
                                                            {o.label != undefined ? <DynamicInputField name={`phoneNumbers[${index}].label`} placeholder={t("label")} disabled={state.isLoading}
                                                                maxLength={50} /> : null}
                                                            <DynamicInputField name={`phoneNumbers[${index}].value`} placeholder={"+218911111111"} disabled={state.isLoading}
                                                                maxLength={50} autoFocus={(o as any)?.autoFocus} dir="ltr"
                                                            />

                                                            <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                        </Stack>)
                                                    }
                                                }
                                                )
                                            }
                                            if (formkikProps.values.phoneNumbers.length <= 3) {
                                                r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                    arrayHelpers.push({ key: (formkikProps.values.phoneNumbers.length + 1) + "", label: undefined, type: 1, value: "", autoFocus: true })
                                                }} />)
                                            }
                                            return r;
                                        }
                                        }

                                    </FieldArray>

                                </Stack.Item>
                            </Stack>

                            {props.type == FormType.ADD ? <Toggle

                                onText={t("confirmed")} offText={t("unconfirmed")}
                                label={t("identity")}
                                disabled={state.isLoading}
                                checked={formkikProps.values.isConfirmed}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isConfirmed', checked);
                                }
                                }
                            /> : null}
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("next")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )
                }
            </Formik >
        </>
    );

}


export const UsersAccountInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const [currentAction, setCurrenctAction] = useState(0)
    const state: { isLoading: boolean, roles: TableState, userTypes: TableState, organization?: OrganizationState , stage1: any} = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading,
            stage1: state.managersUsersForm.stage1,
            roles: state.managersUsersForm.roles,
            userTypes: state.managersUsersForm.userTypes,
            organization: state.managersUsersForm.currentOrganization
        }
    })



    const rolesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('roles'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.roles.hasMore && !state.roles.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.roles.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedRole([state.roles.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.roles.items.length > 0 && !state.roles.isFetching) {
                roleReq.setNextto(state.roles.items.at(state.roles.items.length - 1).id)
                getRolesPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))
            }
        },
        suggestions: state.roles.items.length > 0 ? state.roles.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.roles.items.length == 0 ? state.roles.isFetching : false,
        isSearching: state.roles.items.length > 0 ? state.roles.isFetching : false,

    };

    const userTypeSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('usersTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.userTypes.hasMore && !state.userTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.userTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
            }
        },
        onGetMoreResults: () => {
            if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

            }
        },
        suggestions: state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.userTypes.items.length == 0 ? state.userTypes.isFetching : false,
        isSearching: state.userTypes.items.length > 0 ? state.userTypes.isFetching : false,

    };

    useEffect(() => {
        roleReq.setNumofresults(state.roles.numberOfResults)
        roleReq.setOrder(state.roles.isDescending)
        userTypeReq.setNumofresults(state.userTypes.numberOfResults)
        userTypeReq.setOrder(state.userTypes.isDescending)

        if (props.renderObject?.role)
            dispatch(setSelectedRole(([{ id: props.renderObject.roleId, name: props.renderObject.role }])))

        if (props.renderObject?.usertype)
            dispatch(setSelectedUserType(([{ id: props.renderObject.usertypeId, name: props.renderObject.usertype }])))

        return () => { //clean up
            dispatch(resetRoles())
            dispatch(resetUserTypes())
            getRolesPromise?.abort();
            getUserTypesPromise?.abort();

        }
    }, [])
    const onRoleFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetRoles())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        roleReq.setSearch(wrapper)
        roleReq.setNextto(0)
        dispatch(setIsFilteredRolesSet(false))
        getRolesPromise?.abort();
        getRolesPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))

        return [];

    }
    const onUserTypeFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        dispatch(resetUserTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        userTypeReq.setSearch(wrapper)
        userTypeReq.setNextto(0)
        dispatch(setIsFilteredUserTypesSet(false))
        getUserTypesPromise?.abort();
        getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

        return [];
    }



    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("roles")} onDismiss={() => { setCurrenctAction(0) }} >

                <RolesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedRole([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 101)} title={t("usersTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <UserTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik

                initialValues={{

                    username: (props.renderObject?.username) ? props.renderObject.username : '',
                    usertype: undefined,
                    role: undefined,
                    newPassword: (props.renderObject?.newPassword) ? props.renderObject.newPassword : '',
                    rePassword: (props.renderObject?.rePassword) ? props.renderObject.rePassword : '',
                    isActive: (props.renderObject) ? props.renderObject.isActive : true,
                    primaryEmail: (props.renderObject?.primaryEmail) ? props.renderObject.primaryEmail : '',
                    primaryPhoneNumber: (props.renderObject?.primaryPhoneNumber) ? "+"+phoneNumberFormatter(props.renderObject.primaryPhoneNumber) : '',



                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({

                    username: inputs.username,
                    newPassword: inputs.newPassword,
                    rePassword: inputs.rePassword,
                    primaryEmail: inputs.primaryEmail,
                    primaryPhoneNumber: inputs.primaryPhoneNumber,

                }) : Yup.object({

                    primaryEmail: inputs.primaryEmail,
                    primaryPhoneNumber: inputs.primaryPhoneNumber,

                })}
             
                onSubmit={(values, actions) => {
                  


                    if (props.type == FormType.ADD) {
                        if (values.newPassword != values.rePassword) {
                            actions.setFieldValue("rePassword", "")
                            return;
                        }
                        let role = state.roles.selected.at(0);
                        if (!role) {
                            actions.setFieldError("role", "")
                            return;
                        }
                        let userType = state.userTypes.selected.at(0);
                        if (!userType) {
                            actions.setFieldError("userType", "")
                            return;
                        }


                        req.setFirstname(state.stage1.firstname.trim());
                        req.setLastname(state.stage1.lastname.trim());
                        req.setUsername(values.username.trim().toLowerCase())
                        const phoneNumbers: StringValue[] = [];
                        state.stage1.phoneNumbers.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            phoneNumbers.push(r)
                        })
                        req.setPhonenumbersList(phoneNumbers);

                        const emails: StringValue[] = [];
                        state.stage1.emailAddresses.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            emails.push(r)
                        })
                        req.setEmailaddressesList(emails);

                        req.setUsertype(userType?.id)
                        req.setRole(role?.id)
                        req.setPassword(values.newPassword)
                        req.setIsaccountactive(values.isActive)
                        req.setIsconfirmed(state.stage1.isConfirmed)

                        if (values.primaryEmail.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryEmail.trim());
                            req.setPrimaryemail(r)
                        }

                        if (values.primaryPhoneNumber.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryPhoneNumber.trim());
                            req.setPrimaryphonenumber(r)
                        }

                        /*const address: Address = new Address();
                        address.setCountryiso(state.stage1.addressCountry.key)
                        address.setCity(state.stage1.addressCity)
                        address.setPostalcode(state.stage1.addressPostalCode)
                        address.setFirstline(state.stage1.addressFirstLine)
                        address.setSecondline(state.stage1.addressSecondLine)*/
                        //req.setAddress(address)

                        /*const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)*/

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddUserResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            internalId: res.success?.profileid,
                                            username: values.username.trim().toLowerCase(),
                                            newPassword: values.newPassword,
                                            rePassword: values.rePassword,
                                            primaryEmail: values.primaryEmail,
                                            isActive: values.isActive,
                                            primaryPhoneNumber: values.primaryPhoneNumber,
                                            usertype: state.userTypes.selected.at(0)?.name,
                                            role: state.roles.selected.at(0)?.name,
                                            usertypeId: state.userTypes.selected.at(0)?.id,
                                            roleId: state.roles.selected.at(0)?.id,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos))

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })


                    } else {

                        let role = state.roles.selected.at(0);
                        if (!role) {
                            actions.setFieldError("role", "")
                            return;
                        }
                        let userType = state.userTypes.selected.at(0);
                        if (!userType) {
                            actions.setFieldError("userType", "")
                            return;
                        }

                        updateReq.setProfileid(state.stage1.internalId);
                        updateReq.setFirstname(state.stage1.firstname.trim());
                        updateReq.setLastname(state.stage1.lastname.trim());

                        const phoneNumbers: StringValue[] = [];
                        state.stage1.phoneNumbers.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            phoneNumbers.push(r)
                        })
                        updateReq.setPhonenumbersList(phoneNumbers);

                        const emails: StringValue[] = [];
                        state.stage1.emailAddresses.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            emails.push(r)
                        })
                        updateReq.setEmailaddressesList(emails);

                        updateReq.setUsertype(userType?.id)
                        updateReq.setRole(role?.id)


                        if (values.primaryEmail.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryEmail.trim());
                            updateReq.setPrimaryemail(r)
                        }

                        if (values.primaryPhoneNumber.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(values.primaryPhoneNumber.trim());
                            updateReq.setPrimaryphonenumber(r)
                        }

                        /*const address: Address = new Address();
                        address.setCountryiso(state.stage1.addressCountry.key)
                        address.setCity(state.stage1.addressCity)
                        address.setPostalcode(state.stage1.addressPostalCode)
                        address.setFirstline(state.stage1.addressFirstLine)
                        address.setSecondline(state.stage1.addressSecondLine)
                        updateReq.setAddress(address)

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)*/

                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateUserResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            username: values.username.trim().toLowerCase(),
                                            primaryEmail: values.primaryEmail,
                                            primaryPhoneNumber: values.primaryPhoneNumber,
                                            usertype: state.userTypes.selected.at(0)?.name,
                                            role: state.roles.selected.at(0)?.name,
                                            usertypeId: state.userTypes.selected.at(0)?.id,
                                            roleId: state.roles.selected.at(0)?.id,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                  
                     
                    }
                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >

                            {props.type == FormType.ADD ? <Stack.Item dir="ltr">
                                <Field name="username" label={t("username")} placeholder={t("username")}  component={InputField} autoComplete="one-time-code" disabled={state.isLoading} maxLength={50} />
                            </Stack.Item>
                                : null}
                            {props.type == FormType.ADD ? <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} >
                                <Stack.Item grow>
                                    <Field name="newPassword" label={t("newPassword")} placeholder={t("newPassword")} component={InputField} disabled={state.isLoading} type="password"
                                        canRevealPassword
                                        revealPasswordAriaLabel={t("showPassword")} autoComplete="new-password" maxLength={50} required />
                                </Stack.Item>

                                <Stack.Item grow>
                                    <Field name="rePassword" label={t("rePassword")} placeholder={t("rePassword")} component={InputField} disabled={state.isLoading} type="password"
                                        canRevealPassword
                                        revealPasswordAriaLabel={t("showPassword")} autoComplete="new-password" maxLength={50} required />
                                </Stack.Item>
                            </Stack> : null}
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Label required disabled={state.isLoading}>{t("role")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("role"), required: true }}
                                        disabled={state.isLoading}
                                        suggestionsHeaderText={t('roles')}
                                        isLoading={state.roles.items.length == 0 ? state.roles.isFetching : false}
                                        isSearching={state.roles.items.length > 0 ? state.roles.isFetching : false}
                                        moreSuggestionsAvailable={state.roles.hasMore && !state.roles.isFetching}
                                        suggestions={state.roles.items.length > 0 ? state.roles.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.roles.items.length > 0 && !state.roles.isFetching) {
                                                roleReq.setNextto(state.roles.items.at(state.roles.items.length - 1).id)
                                                getRolesPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.roles.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedRole([state.roles.items.at(u)]))
                                            }

                                        }}
                                        isPeoplePicker={false}
                                        selectedItems={state.roles.selected.length > 0 ? state.roles.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedRole([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetRoles())
                                            roleReq.setSearch(undefined)
                                            roleReq.setNextto(0)
                                            dispatch(setIsFilteredRolesSet(false))
                                            getRolesPromise?.abort()
                                            getRolesPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onRoleFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(100)

                                                }
                                            }]}
                                    />

                                </Stack.Item>

                                <Stack.Item grow>
                                    <Label required disabled={state.isLoading}>{t("usertype")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("usertype"), required: true }}
                                        disabled={state.isLoading}
                                        suggestionsHeaderText={t('usersTypes')}
                                        isLoading={state.userTypes.items.length == 0 ? state.userTypes.isFetching : false}
                                        isSearching={state.userTypes.items.length > 0 ? state.userTypes.isFetching : false}
                                        moreSuggestionsAvailable={state.userTypes.hasMore && !state.userTypes.isFetching}
                                        suggestions={state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                        onGetMoreResults={() => {

                                            if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                                                userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                                                getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {

                                            var u = state.userTypes.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={false}
                                        selectedItems={state.userTypes.selected.length > 0 ? state.userTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedUserType([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetUserTypes())
                                            userTypeReq.setSearch(undefined)
                                            userTypeReq.setNextto(0)
                                            dispatch(setIsFilteredUserTypesSet(false))
                                            getUserTypesPromise?.abort()
                                            getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onUserTypeFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(101)

                                                }
                                            }]}
                                    />

                                </Stack.Item>
                            </Stack>

                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} >
                                <Stack.Item grow>
                                    <Field name="primaryEmail" label={
                                        <div>
                                            {t("email") + ' '}
                                            <TooltipHost content={t("accountEmailDescription")}>
                                                <Icon iconName="Info" aria-label={t("email")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"abc@gmail.com"} dir="ltr" component={InputField} disabled={state.isLoading} type="email"
                                    />
                                </Stack.Item>

                                <Stack.Item grow>
                                    <Field name="primaryPhoneNumber" label={
                                        <div>
                                            {t("phonenumber") + ' '}
                                            <TooltipHost content={t("phoneNumberDescription")}>
                                                <Icon iconName="Info" aria-label={t("phonenumber")} />
                                            </TooltipHost>
                                        </div>
                                    } placeholder={"+218911111111"} dir="ltr" component={InputField} disabled={state.isLoading} />
                                </Stack.Item>
                            </Stack>


                            {props.type == FormType.ADD ? <Toggle
                                checked={formkikProps.values.isActive}
                                onText={t("active")} offText={t("inactive")}
                                disabled={state.isLoading}
                                label={t("accountStatus")}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : null}
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("next")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("back")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    if (props.type == FormType.ADD) {
                                        props.onCancel({
                                            username: formkikProps.values.username.trim().toLowerCase(),
                                            newPassword: formkikProps.values.newPassword,
                                            rePassword: formkikProps.values.rePassword,
                                            primaryEmail: formkikProps.values.primaryEmail,
                                            primaryPhoneNumber: formkikProps.values.primaryPhoneNumber,
                                            isActive: formkikProps.values.isActive,
                                            usertype: state.userTypes.selected.at(0)?.name,
                                            role: state.roles.selected.at(0)?.name,
                                            usertypeId: state.userTypes.selected.at(0)?.id,
                                            roleId: state.roles.selected.at(0)?.id,
                                        })
                                    } else {
                                        props.onCancel({
                                            //   username: props.renderObject.username,
                                            primaryEmail: formkikProps.values.primaryEmail,
                                            primaryPhoneNumber: formkikProps.values.primaryPhoneNumber,
                                            // isAccountActive: props.renderObject.isAccountActive,
                                            usertype: state.userTypes.selected.at(0)?.name,
                                            role: state.roles.selected.at(0)?.name,
                                            usertypeId: state.userTypes.selected.at(0)?.id,
                                            roleId: state.roles.selected.at(0)?.id,
                                        })
                                    }
                                }
                            }} />
                        </DialogFooter>


                    </Form>
                )
                }
            </Formik >
        </>
    );

}


export const UsersOtherInfoForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const state: { isLoading: boolean | undefined, stage1: any, stage2: any, stage3: any } = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading,
            stage1: state.managersUsersForm.stage1
            , stage2: state.managersUsersForm.stage2
            , stage3: state.managersUsersForm.stage3
        }
    })


    return (
        <>

            <Formik


                initialValues={{


                    extraFields: (props.renderObject?.extraFields) ? props.renderObject.extraFields as DynamicField[] : [] as DynamicField[]

                }}

                validationSchema={Yup.object({


                    extraFields: inputs.extraFields,


                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        req.setFirstname(state.stage1.firstname.trim());
                        req.setLastname(state.stage1.lastname.trim());
                        req.setUsername(state.stage2.username.trim())
                        const phoneNumbers: StringValue[] = [];
                        state.stage1.phoneNumbers.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            phoneNumbers.push(r)
                        })
                        req.setPhonenumbersList(phoneNumbers);

                        const emails: StringValue[] = [];
                        state.stage1.emailAddresses.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            emails.push(r)
                        })
                        req.setEmailaddressesList(emails);

                        req.setUsertype(state.stage2.usertypeId)
                        req.setRole(state.stage2.roleId)
                        req.setPassword(state.stage2.newPassword)
                        req.setIsaccountactive(state.stage2.isActive)
                        req.setIsconfirmed(state.stage1.isConfirmed)

                        if (state.stage2.primaryEmail.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(state.stage2.primaryEmail.trim());
                            req.setPrimaryemail(r)
                        }

                        if (state.stage2.primaryPhoneNumber.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(state.stage2.primaryPhoneNumber.trim());
                            req.setPrimaryphonenumber(r)
                        }

                        /*const address: Address = new Address();
                        address.setCountryiso(state.stage1.addressCountry.key)
                        address.setCity(state.stage1.addressCity)
                        address.setPostalcode(state.stage1.addressPostalCode)
                        address.setFirstline(state.stage1.addressFirstLine)
                        address.setSecondline(state.stage1.addressSecondLine)*/
                        //req.setAddress(address)

                        /*const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)*/

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddUserResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            internalId: res.success?.profileid,
                                            extraFields: values.extraFields,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos))

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setProfileid(state.stage1.internalId);
                        updateReq.setFirstname(state.stage1.firstname.trim());
                        updateReq.setLastname(state.stage1.lastname.trim());

                        const phoneNumbers: StringValue[] = [];
                        state.stage1.phoneNumbers.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            phoneNumbers.push(r)
                        })
                        updateReq.setPhonenumbersList(phoneNumbers);

                        const emails: StringValue[] = [];
                        state.stage1.emailAddresses.forEach((e: DynamicField) => {
                            const r = new StringValue();
                            r.setValue(e.value.trim());
                            emails.push(r)
                        })
                        updateReq.setEmailaddressesList(emails);

                        updateReq.setUsertype(state.stage2.usertypeId)
                        updateReq.setRole(state.stage2.roleId)


                        if (state.stage2.primaryEmail.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(state.stage2.primaryEmail.trim());
                            updateReq.setPrimaryemail(r)
                        }

                        if (state.stage2.primaryPhoneNumber.trim().length > 0) {
                            const r = new StringValue();
                            r.setValue(state.stage2.primaryPhoneNumber.trim());
                            updateReq.setPrimaryphonenumber(r)
                        }

                        /*const address: Address = new Address();
                        address.setCountryiso(state.stage1.addressCountry.key)
                        address.setCity(state.stage1.addressCity)
                        address.setPostalcode(state.stage1.addressPostalCode)
                        address.setFirstline(state.stage1.addressFirstLine)
                        address.setSecondline(state.stage1.addressSecondLine)
                        updateReq.setAddress(address)

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)*/

                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateUserResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            extraFields: values.extraFields,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading} iconProps={{ iconName: "remove" }} onClick={() => {
                                                        arrayHelpers.remove(index)
                                                    }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading} key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                            arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "" })
                                        }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("back")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel({
                                        extraFields: formkikProps.values.extraFields,
                                    })
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )
                }
            </Formik >
        </>
    );

}
